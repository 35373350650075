import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useEffect } from "react";
import type {
  IActivitySchedule,
  IRescheduleActivity,
  ITime,
} from "@/api/Activities";
import { ANY_TIME_OF_DAY, rescheduleActivity } from "@/api/Activities";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { FormProvider, useForm } from "react-hook-form";
import type { IRecurrencePickerFields } from "@/forms/AddActivityForm/Scheduling/RecurrencePicker";
import { RecurrencePicker } from "@/forms/AddActivityForm/Scheduling/RecurrencePicker";
import {
  recurringNumberDictionary,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import {
  recurrencesPerDayOptionSchema,
  timeSensitivityOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/timeSlotsUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IWeekdaysPickerFields } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import { WeekdaysPicker } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import {
  getTimeFields,
  getTimespan,
} from "@/forms/AddActivityForm/activityTimeUtils";
import { timeOfDaySchema } from "@models/activities";

type IEditScheduleFields = IRecurrencePickerFields &
  IWeekdaysPickerFields &
  ITimeSlotsPickerFields;

export const EditSchedule = ({
  activityId,
  schedule,
  onSuccess,
}: {
  activityId: string;
  schedule: IActivitySchedule;
  onSuccess: () => void;
}) => {
  const scheduleTypeIsRecurring = "interval" in schedule || "days" in schedule;
  const scheduleTypeIsInterval = "interval" in schedule;
  const scheduleTypeIsWeekdays = "days" in schedule;

  const times = scheduleTypeIsRecurring ? schedule.times : [];
  const isAnyTimeOfDay = times === ANY_TIME_OF_DAY;
  const hasTimeslots = !isAnyTimeOfDay && times.length > 0;

  const timeSensitivity = hasTimeslots
    ? schedule.span === "00:15:00"
      ? "0.25"
      : schedule.span === "00:30:00"
        ? "0.5"
        : schedule.span === "01:00:00"
          ? "1"
          : schedule.span === "02:00:00"
            ? "2"
            : schedule.span === "04:00:00"
              ? "4"
              : schedule.span === "12:00:00"
                ? "12"
                : undefined
    : undefined;

  const methods = useForm<IEditScheduleFields>({
    defaultValues: {
      recurrence: scheduleTypeIsInterval
        ? recurringNumberDictionary[schedule.interval]
        : scheduleTypeIsWeekdays
          ? recurringOptionSchema.Values.custom
          : undefined,
      timeCategory: isAnyTimeOfDay
        ? timeOfDaySchema.Values.Any
        : timeOfDaySchema.Values.Specific,
      recurrencesPerDay: hasTimeslots
        ? recurrencesPerDayOptionSchema.parse(times.length.toString())
        : "1",
      timeslots: hasTimeslots ? times.map((time: ITime) => ({ time })) : [],
      timeSensitivity:
        timeSensitivity === undefined
          ? undefined
          : timeSensitivityOptionSchema.parse(timeSensitivity),
      weekdays: scheduleTypeIsWeekdays ? schedule.days : [],
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset,
    setError,
    unregister,
    watch,
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (formData: IRescheduleActivity) =>
      rescheduleActivity(activityId, formData),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      onSuccess();
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    const { recurrence, timeCategory, timeSensitivity, timeslots, weekdays } =
      validatedFormData;

    const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;

    const isRecurringAsInterval =
      recurrence !== recurringOptionSchema.Values.custom &&
      recurrence !== recurringOptionSchema.Values.never;

    const rescheduleActivityFields: IRescheduleActivity = {
      span: getTimespan({ isAnyTimeOfDay, timeSensitivity }),
      ...getTimeFields({
        isRecurringAsInterval,
        isAnyTimeOfDay,
        timeslots,
        weekdays,
        recurrence,
      }),
    };

    mutate(rescheduleActivityFields);
  });

  const isRecurringOnWeekdays =
    watch("recurrence") === recurringOptionSchema.Values.custom;

  useEffect(() => {
    if (!isRecurringOnWeekdays) {
      unregister("weekdays");
    }
  }, [isRecurringOnWeekdays, unregister]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        {isPending || isSuccess ? (
          <Loading message={t`Ändrar schemat`} />
        ) : errors.root?.server?.message && !isDirty ? (
          <ErrorMessage message={errors.root.server.message} />
        ) : (
          <></>
        )}
        <RecurrencePicker onlyRecurringOptions />
        {isRecurringOnWeekdays ? <WeekdaysPicker /> : <></>}
        <TimeSlotsPicker isRecurring />
        {isDirty ? (
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        ) : (
          <></>
        )}
      </Form>
    </FormProvider>
  );
};
