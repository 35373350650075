import { queryOptions } from "@tanstack/react-query";
import { authApi } from "./ApiClient";
import { z } from "zod";

const otpSchema = z.object({ otp: z.string() });
export const generateOtp = async (patientId: string) => {
  const otpResponse = await authApi.post(`/api/otp`, {
    subjectId: patientId,
  });

  const parsedOtp = otpSchema.parse(otpResponse.data).otp;
  return parsedOtp;
};

const pinSchema = z.object({ pin: z.string() });
export const generateTemporaryPin = async (patientId: string) => {
  const pinResponse = await authApi.post(`/api/pin/temporary`, {
    subjectId: patientId,
  });

  const parsedPin = pinSchema.parse(pinResponse.data).pin;
  return parsedPin;
};

export const signOutPatient = async (patientId: string) => {
  await authApi.delete(`/api/session/user/${patientId}`);
};

const sessionsSchema = z.array(z.unknown());
const fetchPatientSessions = async (patientId: string) => {
  const patientSessions = await authApi.get(`/api/session/user/${patientId}`);
  const parsedPatientSessions = sessionsSchema.parse(patientSessions.data);
  return parsedPatientSessions;
};

const TEN_SECONDS = 10 * 1000;
export const isPatientSignedInQueryOptions = (patientId: string) =>
  queryOptions({
    queryKey: ["is-patient-signed-in", patientId],
    queryFn: async () => {
      const patientSessions = await fetchPatientSessions(patientId);
      const isPatientSignedIn = patientSessions.length >= 1;
      return isPatientSignedIn;
    },
    // Lacking SSE, keep status updated
    refetchInterval: TEN_SECONDS,
  });
