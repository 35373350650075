import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useForm, FormProvider } from "react-hook-form";
import Form from "@/components/Form/Form";
import { FilledButton, OutlinedButton } from "@components/Button/Button";
import TextArea from "@/components/TextArea/TextArea";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import Checkbox from "@/components/Checkbox/Checkbox";
import styles from "./CreatePatientNoteForm.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { convertPatientNote } from "@/api/PatientNote";
import { useParams } from "react-router";
import { PatientNoteTypePicker } from "./PatientNoteTypePicker";
import { z } from "zod";
import React, { useEffect, useState } from "react";
import type {
  ICreatePatientNote,
  IPatientNoteNoteKeys,
} from "@models/patientNotes";
import {
  noteByTypeDictionary,
  patientNoteStates,
  patientNoteStatusSchema,
  patientNoteTypeSchema,
  typeDictionary,
} from "@models/patientNotes";
import { useLingui } from "@lingui/react";

export type ICreatePatientNoteFormData = ICreatePatientNote & {
  confirmText: boolean;
};

const CreatePatientNoteForm = ({
  patientNote,
  createdPatientNoteState,
}: {
  patientNote: ICreatePatientNote | null;
  createdPatientNoteState: (noteState: string) => void;
}) => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());

  const methods = useForm<ICreatePatientNoteFormData>({
    defaultValues: {
      anonymisedNote: patientNote?.anonymisedNote ?? {},
      confirmText: false,
    },
  });

  const queryClient = useQueryClient();

  const [
    isCurrentlyEditingAlreadyConverted,
    setIsCurrentlyEditingAlreadyConverted,
  ] = useState(false);

  const isConverted = patientNote
    ? patientNote.status === patientNoteStatusSchema.Values.succeeded ||
      patientNote.status === patientNoteStatusSchema.Values.accepted
    : false;

  const {
    formState: { errors },
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
  } = methods;

  useEffect(() => {
    reset({
      anonymisedNote: patientNote?.anonymisedNote ?? {},
      confirmText: false,
    });
  }, [patientNote, reset]);

  const { mutate, isPending } = useMutation({
    mutationFn: ({ newPatientNote }: { newPatientNote: ICreatePatientNote }) =>
      convertPatientNote(newPatientNote),
    onError: () => {
      displayErrorMessageAlert(
        t`Det gick inte att översätta texten för patient. Försök igen om en stund, och rapportera ett fel om det ändå inte fungerar.`,
      );
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onMutate: () => {
      createdPatientNoteState(patientNoteStates.MUTATED);
    },
    onSuccess: () => {
      createdPatientNoteState(patientNoteStates.SUBMITTED);
      setIsCurrentlyEditingAlreadyConverted(false);
      return queryClient.invalidateQueries({ queryKey: ["conversion"] });
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const { anonymisedNote } = formData;

    if (anonymisedNote.type === patientNoteTypeSchema.Values.TransferNote) {
      const newPatientNote: ICreatePatientNote = {
        patientId,
        anonymisedNote: {
          ...anonymisedNote,
        },
      };
      mutate({ newPatientNote });
    } else if (anonymisedNote.type === patientNoteTypeSchema.Values.Epicrisis) {
      const newPatientNote: ICreatePatientNote = {
        patientId,
        anonymisedNote: {
          ...anonymisedNote,
        },
      };
      mutate({ newPatientNote });
    } else if (
      anonymisedNote.type === patientNoteTypeSchema.Values.GeneralNote
    ) {
      const newPatientNote: ICreatePatientNote = {
        patientId,
        anonymisedNote: {
          ...anonymisedNote,
        },
      };
      mutate({ newPatientNote });
    }
  });

  const editConvertedPatientNote = () => {
    createdPatientNoteState(patientNoteStates.EDITED);
    setIsCurrentlyEditingAlreadyConverted(true);
  };

  const cancelEditingConvertedPatientNote = () => {
    createdPatientNoteState(patientNoteStates.CANCEL_EDITING);
    setIsCurrentlyEditingAlreadyConverted(false);
  };

  const currentlyChosenNoteType = watch()?.anonymisedNote?.type;
  const { _ } = useLingui();

  if (!isCurrentlyEditingAlreadyConverted && isConverted) {
    return (
      <div className={styles.noEditContainer}>
        <Heading level="h2">
          <Trans>Lägg in anteckning</Trans>
        </Heading>
        {currentlyChosenNoteType && (
          <Heading level="h3">
            {_(typeDictionary[currentlyChosenNoteType])}
          </Heading>
        )}
        {patientNote && (
          <>
            <div>
              {Object.entries(
                noteByTypeDictionary[currentlyChosenNoteType] ?? {},
              ).map(([label, note]) => {
                const key =
                  label as keyof typeof patientNote.anonymisedNote.note;
                if (!patientNote.anonymisedNote.note[key]) {
                  return null;
                }
                const localizedNote = _(note);

                return (
                  <React.Fragment key={localizedNote}>
                    <Heading level="h3">{localizedNote}</Heading>
                    <Text element="p">
                      {patientNote.anonymisedNote.note[key]}
                    </Text>
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.buttons}>
              <OutlinedButton onClick={editConvertedPatientNote}>
                <Trans>Ändra anteckning</Trans>
              </OutlinedButton>
            </div>
          </>
        )}
      </div>
    );
  }

  if (isCurrentlyEditingAlreadyConverted || !isConverted) {
    return (
      <>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <Heading level="h2">
              <Trans>Lägg in anteckning</Trans>
            </Heading>
            <Form.Section>
              <PatientNoteTypePicker />
              {currentlyChosenNoteType ? (
                <>
                  {Object.entries(
                    noteByTypeDictionary[currentlyChosenNoteType] ?? {},
                  ).map(([label, note]) => {
                    const localizedNote = _(note);
                    return (
                      <TextArea
                        key={label + localizedNote}
                        label={localizedNote}
                        showOptionalLabel={false}
                        rows={6}
                        {...register(
                          // typescript wants more specific typing here
                          `anonymisedNote.note.${label}` as `anonymisedNote.note.${IPatientNoteNoteKeys}`,
                        )}
                      />
                    );
                  })}
                  <section className={styles.personalDataInformation}>
                    <Trans>
                      <Text element="p">
                        <strong>
                          Bekräfta att texten inte innehåller information som
                          kan användas för att förstå vem någon är.
                        </strong>{" "}
                        Detta gäller både patienter, deras anhöriga och egen
                        personal.
                      </Text>
                      <Text element="p">
                        Till personlig information räknas exempelvis:
                      </Text>
                      <ul>
                        <li>Adress, telefonnummer eller namn på mindre by</li>
                        <li>Sällsynta sjukdomar</li>
                      </ul>
                    </Trans>
                  </section>

                  <Checkbox
                    errorMessage={errors.confirmText?.message}
                    label={{
                      text: t`Jag bekräftar att texten inte innehåller personlig information`,
                    }}
                    {...register("confirmText", {
                      required: {
                        value: true,
                        message: t`Bekräfta att texten inte innehåller personlig information`,
                      },
                    })}
                  />
                </>
              ) : (
                <></>
              )}
            </Form.Section>
            {currentlyChosenNoteType ? (
              <Form.SubmitButtonWrapper>
                <div className={styles.buttons}>
                  {isCurrentlyEditingAlreadyConverted && (
                    <OutlinedButton onClick={cancelEditingConvertedPatientNote}>
                      <Trans>Avbryt</Trans>
                    </OutlinedButton>
                  )}
                  <FilledButton type="submit" disabled={isPending}>
                    <Trans>Skapa förenklad text</Trans>
                  </FilledButton>
                </div>
              </Form.SubmitButtonWrapper>
            ) : (
              <></>
            )}
          </Form>
        </FormProvider>
      </>
    );
  }
};

export default CreatePatientNoteForm;
