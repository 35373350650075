import { t } from "@lingui/core/macro";
import { useContext, useRef, useState } from "react";
import Chat from "@components/Chat/Chat";
import { Loading } from "@components/Loading/Loading";
import ChatContext from "@chat/ChatContext";
import {
  formatTimestamp,
  makeMessages,
  useMatrixTimelineEvents,
} from "@chat/useMatrixEvents";
import { useMatrixRoom } from "@chat/useMatrixRoom";
import { useParams } from "react-router";
import { z } from "zod";
import { usePatient } from "@/api/Patients";
import { sendTextMessage } from "@chat/matrixClient";
import { useSendReadReceipt } from "@chat/useSendReadReceipt";
import { useChatScroll } from "@chat/useChatScroll";
import { getHistory } from "@chat/getHistory";
import { SyncState } from "matrix-js-sdk";
import { useMatrixConnectionStatus } from "@chat/useMatrixConnectionStatus";
import {
  lastEventReadByUser,
  useLastReadEventPerUser,
} from "@chat/useLastReadEvent";
import { useCurrentEmployee } from "@/api/Employee";
import { employeeName, medicalTitle } from "@models/shifts";
import { useOtherUserIsTyping } from "@chat/useOtherUserIsTyping";
import { getPatientRoomAlias } from "@chat/roomAlias";

const ChatComponent = () => {
  const [pendingMessage, setPendingMessage] = useState("");

  const { matrixClient } = useContext(ChatContext);

  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const roomAlias = getPatientRoomAlias({ patientId, matrixClient });
  const patientMatrixUser = `@${patientId}:${matrixClient?.getDomain()}`;
  const chatConnection = useMatrixConnectionStatus(matrixClient);
  const { roomId, getRoom } = useMatrixRoom(matrixClient, roomAlias);
  const userId = matrixClient?.getUserId();
  const { data: employee } = useCurrentEmployee();
  const { otherUserIsTyping: patientIsTyping } = useOtherUserIsTyping({
    matrixClient,
    otherUserMatrixId: patientMatrixUser,
  });

  const timelineEvents = useMatrixTimelineEvents(matrixClient, roomId);

  useSendReadReceipt(matrixClient, timelineEvents, userId);

  const messages = userId ? makeMessages(timelineEvents, userId) : [];

  const messagePanelRef = useRef<HTMLDivElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const { scrollMessagePanelToBottom } = useChatScroll({
    messagePanelRef,
    inputTextAreaRef,
    messages,
  });

  const lastEventReadByPatient = lastEventReadByUser(
    useLastReadEventPerUser({
      getRoom,
    }),
    patientMatrixUser,
  );

  const { data: patient } = usePatient(patientId);

  const shouldRenderInfoPanel =
    patientIsTyping || chatConnection === SyncState.Error;

  const room = getRoom();
  if (!matrixClient || !room) {
    return <Loading message={t`Laddar chatt`} padding={24} />;
  }
  const patientName = patient?.name ?? t`Patienten`;
  return (
    <Chat>
      <Chat.MessagePanel
        getHistory={() => getHistory(matrixClient, room)}
        ref={messagePanelRef}
      >
        {messages.map((message) =>
          message.sender.isMe ? (
            <Chat.MessageGroup
              key={message.id}
              placement="right"
              hideAvatar={true}
              status={message.status}
              timestamp={formatTimestamp(message.epochTimestamp)}
              senderName={message.sender.name ?? "Medoma"}
              senderTitle={medicalTitle(message.sender.title, {
                format: "short",
              })}
              readIndicator={
                message.id === lastEventReadByPatient?.eventId
                  ? lastEventReadByPatient?.timestamp
                  : undefined
              }
            >
              <Chat.Message type="sent-by-me" message={message.text} />
            </Chat.MessageGroup>
          ) : (
            <Chat.MessageGroup
              key={message.id}
              placement="left"
              senderName={patient?.name ?? t`Patient`}
              status={message.status}
              timestamp={formatTimestamp(message.epochTimestamp)}
            >
              <Chat.Message type="received" message={message.text} />
            </Chat.MessageGroup>
          ),
        )}
      </Chat.MessagePanel>
      {shouldRenderInfoPanel ? (
        <Chat.InfoPanel
          type={chatConnection === SyncState.Error ? "error" : "info"}
        >
          {chatConnection === SyncState.Error ? (
            t`Chatten fungerar inte just nu`
          ) : (
            <Chat.IsTyping text={t`${patientName} skriver`} />
          )}
        </Chat.InfoPanel>
      ) : (
        <></>
      )}

      <Chat.InputPanel>
        <Chat.InputTextArea
          inputTextAreaRef={inputTextAreaRef}
          value={pendingMessage}
          onChange={(e) => {
            setPendingMessage(e.currentTarget.value);
          }}
          onFocus={scrollMessagePanelToBottom}
        />
        <Chat.SendMessageButton
          aria-label={t`Skicka`}
          disabled={pendingMessage.trim() === ""}
          onClick={() =>
            sendTextMessage(
              matrixClient,
              room.roomId,
              pendingMessage,
              () => setPendingMessage(""),
              employee ? `${employeeName(employee)}` : "Medoma",
              employee?.imageURL ?? null,
              employee?.competence ?? undefined,
            )
          }
        />
      </Chat.InputPanel>
    </Chat>
  );
};

export default ChatComponent;
