import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { graphApi } from "./ApiClient";
import { useAuth } from "react-oidc-context";

const userSchema = z.object({
  displayName: z.string().nullable(),
  employeeId: z.string().nullable(),
  givenName: z.string().nullable(),
  id: z.string(),
  jobTitle: z.string().nullable(),
  mail: z.string().nullable(),
  mobilePhone: z.string().nullable(),
});

export type IUser = z.infer<typeof userSchema>;

export async function fetchUser(id?: string) {
  const path = id ? `/users/${id}` : "/me";
  const result = await graphApi.get(
    `${path}?$select=displayName,employeeId,givenName,id,jobTitle,mail,mobilePhone`,
  );
  const user = userSchema.parse(result.data);
  return user;
}

export const useUser = (id?: string) => {
  return useQuery({ queryKey: ["users", id], queryFn: () => fetchUser(id) });
};

export const useUnit = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error(
      "No user found. This is usually because the hook is executed outside of an AuthProvider, or because the authentication is not completed yet.",
    );
  }

  const unit = z.string().parse(user.profile.unit, {
    errorMap: () => ({
      message:
        "The id_token did not include a 'unit' property. Did you forget the 'organization' scope?",
    }),
  });

  return unit;
};
