import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi } from "./ApiClient";

export const knownFeatureFlagsSchema = z.enum([
  "unit-test-flag",
  "MapboxGeocodingV6",
  "ActivityGrouping",
  "EmployeeDesktopChatNotifications",
  "DisableRouteAllocationInOldViews",
  "QuickActivities",
  "PatientNote",
  "UseOTPForLoginPatient",
  "DisableChangeRecurrence",
  "NewVideoSolution",
  "ACSVideo",
  "CardiologyWorkflow",
  "DisplayTravellingTimes",
  "AllocateInShiftsView",
  "ASIHCustomer",
  "CareEpisodeInvoicing",
  "Area",
  "PERMANENT_UseAbax",
]);

const featureFlagSchema = z.object({
  name: knownFeatureFlagsSchema,
  isEnabled: z.boolean(),
});

export type IFeatureFlag = z.infer<typeof featureFlagSchema>;

const featureFlagKeys = {
  all: ["featureFlags"] as const,
  flag: (key: IFeatureFlag["name"]) => ["featureFlag", key] as const,
};

async function getFeatureFlagStatus(key: IFeatureFlag["name"]) {
  const featureFlag = await logisticsApi.get(`/features/${key}`);
  return featureFlagSchema.parse(featureFlag.data).isEnabled;
}

export const useFeatureFlag = (key: IFeatureFlag["name"]) => {
  return useQuery({
    queryKey: featureFlagKeys.flag(key),
    queryFn: () => getFeatureFlagStatus(key),
  });
};
