import RadioButtons from "@/components/RadioButton/RadioButtons";
import {
  timeSensitivityDictionary,
  timeSensitivityOptions,
  type ITimeSensitivityOption,
} from "./timeSlotsUtils";
import RadioButton from "@/components/RadioButton/RadioButton";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react/macro";

type ITimeSensitivityFields = {
  timeSensitivity: ITimeSensitivityOption;
};

export const TimeSensitivityPicker = () => {
  const { t } = useLingui();
  const {
    formState: { errors },
    register,
  } = useFormContext<ITimeSensitivityFields>();

  return (
    <RadioButtons
      legend={t`Tidsflexibilitet`}
      errorMessage={errors.timeSensitivity?.message}
      orientation="horizontal"
    >
      {timeSensitivityOptions.map((timeSensitivity) => (
        <RadioButton
          key={timeSensitivity}
          label={{ text: t(timeSensitivityDictionary[timeSensitivity]) }}
          value={timeSensitivity}
          visualStyle="framed"
          {...register(`timeSensitivity`, {
            required: {
              value: true,
              message: t`Aktiviteten behöver en tidsflexibilitet`,
            },
          })}
        />
      ))}
    </RadioButtons>
  );
};
