import { msg } from "@lingui/core/macro";
import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import type { MessageDescriptor } from "@lingui/core";
import { allPatientTypes, type IPatientType } from "@models/patients";

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "frequency"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
    patientTypes: IPatientType[];
  };
  templateId: string;
  templateRevision: number;
  title: MessageDescriptor;
  description: MessageDescriptor | null;
};

type IAnyTimeOfDayTemplate = Omit<
  IBaseTemplate,
  "timeslots" | "timeSensitivity" | "recurrencesPerDay"
>;

// Must be a subset of IAddActivityFormData
export type ITemplate = IBaseTemplate | IAnyTimeOfDayTemplate;

export type ITemplateKey =
  | "bloodSample"
  | "replaceAlarm"
  | "deliverMaterialBox"
  | "retrieveMaterialBox"
  | "retrieveTechBox"
  | "handoverTreatmentMessage"
  | "patientSurvey"
  | "returnKey"
  | "keyQuittance"
  | "removeCatheter"
  | "checkCatheter"
  | "riskAssessment"
  | "deliverAlarm"
  | "medicationWalkthrough"
  | "replaceMaterialBox"
  | "replaceAlarm"
  | "round"
  | "patientVitalsMorning"
  | "fluidMeasurement"
  | "patientVitalsEvening"
  | "retrieveAlarmClock"
  | "deliverMedication"
  | "connectMedicationPump"
  | "medicationPumpReplacement"
  | "medicationPumpCheck"
  | "takeMedicineOnce"
  | "takeMedicineTwice"
  | "exchangePVK"
  | "takeMedicineThreeTimes"
  | "ownMeasurementBloodSugar"
  | "takeMedicineFourTimes"
  | "ownMeasurementWeight"
  | "intravenousMedication"
  | "prepareDosette"
  | "redoCentral"
  | "medicationSubcutaneousAdministration"
  | "removeMedicationPump"
  | "deliverFood"
  | "takeMedicineFiveTimes"
  | "takeMedicineSixTimes"
  | "handoverMedicationList"
  | "securityReceipt"
  | "teamRound"
  | "medicationReviewSimple"
  | "medicationReviewAdvanced"
  | "bloodTransfusion";

//TODO: add invoicing codes
export const templates: Record<ITemplateKey, ITemplate> = {
  bloodSample: {
    title: msg`Blodprov`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "15b9015e-fac6-4aa3-039c-1683760d2c33",
    templateRevision: 1,
  },
  bloodTransfusion: {
    title: msg`Blodtransfusion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: recurringOptionSchema.Values.never,
    frequency: activityFrequencySchema.Values.oneTime,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "adcaa5ea-be14-412d-15dd-295d9c9bc0d3",
    templateRevision: 1,
  },
  medicationPumpReplacement: {
    title: msg`Byte av pump`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Kom ihåg att ändra tiden till 24 timmar efter att pumpen kopplats och ta sedan bort "dold för patienten"`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "4aa85636-438a-4c1f-e6db-ff0439795d7f",
    templateRevision: 1,
  },
  replaceAlarm: {
    title: msg`Byte larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Fyll i klockans larmnummer under Patientinformation`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every3rdDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "909749e9-9755-4ea4-ca74-5588d03365a5",
    templateRevision: 1,
  },
  replaceMaterialBox: {
    title: msg`Byte materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every5thDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "4c44b29b-f173-496b-2653-77d06ae1b4fb",
    templateRevision: 1,
  },
  exchangePVK: {
    title: msg`Byte PVK - perfier infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every3rdDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "639d2dac-c8e4-40bf-0b07-44a128476a8b",
    templateRevision: 1,
  },
  prepareDosette: {
    title: msg`Dosettförberedelse`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "fb064001-c422-4787-e44d-ad007900c453",
    templateRevision: 1,
  },
  removeCatheter: {
    title: msg`Dra infarter`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "4a46a3ee-8892-488a-ba06-991d4f918ddd",
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: msg`Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "08a98552-a695-418c-0b72-4a16eebd8971",
    templateRevision: 1,
  },
  patientVitalsEvening: {
    title: msg`Egen mätning kväll`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "17:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "4029f33c-9219-4877-2896-356281c5eb39",
    templateRevision: 1,
  },
  patientVitalsMorning: {
    title: msg`Egen mätning morgon`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "5c378477-6ad8-427c-4ce2-a4330326dbb7",
    templateRevision: 1,
  },
  ownMeasurementWeight: {
    title: msg`Egen mätning vikt`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["weight"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "29cdfd78-2404-4795-8195-d2d945bc77aa",
    templateRevision: 1,
  },
  retrieveAlarmClock: {
    title: msg`Hämtning larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "8094a365-fa6a-42cf-5a24-225f30bbff6b",
    templateRevision: 1,
  },
  retrieveMaterialBox: {
    title: msg`Hämtning materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "9ec230a2-becc-4840-be8a-67b25e2edc4f",
    templateRevision: 1,
  },
  retrieveTechBox: {
    title: msg`Hämtning tekniklåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "d3cccaa3-b969-4a63-3508-ba90c4af8acc",
    templateRevision: 1,
  },
  checkCatheter: {
    title: msg`Kontroll infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "018f0e9f-8a9b-4def-1fa8-b4e68c4abf4e",
    templateRevision: 1,
  },
  connectMedicationPump: {
    title: msg`Koppla pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "03df1a61-e695-4a06-5204-6495296e81a0",
    templateRevision: 1,
  },
  removeMedicationPump: {
    title: msg`Koppla bort pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "e810f5f6-49bf-4c57-751e-4e36fa989b1a",
    templateRevision: 1,
  },
  deliverAlarm: {
    title: msg`Leverans larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "a0a14f82-b070-40c2-59b0-5e59b282ba33",
    templateRevision: 1,
  },
  deliverMedication: {
    title: msg`Leverans läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "699804c2-15f3-4d56-1257-d36098ab2e97",
    templateRevision: 1,
  },
  deliverFood: {
    title: msg`Leverans mat`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Fråga patient om önskemål och eventuella allergier`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every2ndDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "4d1105f6-518e-4edc-6284-963e0a0acb0f",
    templateRevision: 1,
  },
  deliverMaterialBox: {
    title: msg`Leverans materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "cd867219-90c1-45ca-b090-ce4486f030f5",
    templateRevision: 1,
  },
  intravenousMedication: {
    title: msg`Läkemedel intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "00:00" }, { time: "08:00" }, { time: "16:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "4447a1e4-2ead-4544-1a34-4329a5f1d035",
    templateRevision: 1,
  },
  medicationSubcutaneousAdministration: {
    title: msg`Läkemedel subkutan injektion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "c3ac176c-c54b-4394-21d5-a308679d2b51",
    templateRevision: 1,
  },
  medicationReviewSimple: {
    title: msg`Läkemedelsgenomgång, enkel`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: true,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "dbf69b69-314f-4522-9605-2da378ccde55",
    templateRevision: 1,
  },
  medicationReviewAdvanced: {
    title: msg`Läkemedelsgenomgång, fördjupad`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: true,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "7a77c1b9-3194-4c2b-9452-e6289b35c3c5",
    templateRevision: 1,
  },
  medicationWalkthrough: {
    title: msg`Läkemedelsgenomgång`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "a8f2c3c8-8d06-40aa-cb9e-e87d582b5b80",
    templateRevision: 1,
  },
  keyQuittance: {
    title: msg`Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "713cd2e5-8986-4d3f-be3f-05f1f148a4f6",
    templateRevision: 1,
  },
  redoCentral: {
    title: msg`Omläggning central infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "95c0e380-ec5a-4761-eaf6-4599b10fecfd",
    templateRevision: 1,
  },
  patientSurvey: {
    title: msg`Patientenkät`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "9ee7af86-08b0-41e9-081d-1d6aa37cc6bf",
    templateRevision: 1,
  },
  medicationPumpCheck: {
    title: msg`Pumpkontroll digital`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "18:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "c024914b-68b6-46f2-b7d4-96b0efff5b57",
    templateRevision: 1,
  },
  riskAssessment: {
    title: msg`Riskbedömning`,
    category: categorySchema.Values.HomeVisit,
    description: msg`För in i Cosmic`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "d00182c9-2f23-4c81-2d31-d73f60d3f2c6",
    templateRevision: 1,
  },
  round: {
    title: msg`Rond`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [
      medicalCompetenceSchema.Values.NursePractitioner,
      medicalCompetenceSchema.Values.MedicalDoctor,
    ],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "ce2786b8-4baa-4a90-081a-0175c5112398",
    templateRevision: 1,
  },
  takeMedicineOnce: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "e4017c05-f855-4780-2576-7201821c37bd",
    templateRevision: 1,
  },
  takeMedicineTwice: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "0c90b972-ec46-46fc-47f4-005e1f01981c",
    templateRevision: 1,
  },
  takeMedicineThreeTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "08:00" }, { time: "14:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "68581fde-bcb3-4729-16dd-703fb293896b",
    templateRevision: 1,
  },
  takeMedicineFourTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "4",
    timeslots: [
      { time: "08:00" },
      { time: "14:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "57e5dad8-7cf8-403f-3454-23c08189ea59",
    templateRevision: 1,
  },
  takeMedicineFiveTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "5",
    timeslots: [
      { time: "08:00" },
      { time: "13:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "a0b54b96-789f-4624-2fc1-38308c63cb9c",
    templateRevision: 1,
  },
  takeMedicineSixTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "6",
    timeslots: [
      { time: "06:00" },
      { time: "10:00" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "86925982-1ad8-418b-8189-2cb91240999f",
    templateRevision: 1,
  },
  teamRound: {
    title: msg`Teamrond`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [
      medicalCompetenceSchema.Values.NursePractitioner,
      medicalCompetenceSchema.Values.MedicalDoctor,
    ],
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "0.25",
    doubleStaffing: true,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "6e082062-5fa7-419c-043a-4df9b7b7d8c5",
    templateRevision: 1,
  },
  fluidMeasurement: {
    title: msg`Vätske- och urinmätning`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "d0749eb7-682d-41b2-ff21-d70372e78494",
    templateRevision: 1,
  },
  returnKey: {
    title: msg`Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "31fd878f-de34-490b-1418-6e087a7f61ed",
    templateRevision: 1,
  },
  handoverTreatmentMessage: {
    title: msg`Överlämning behandlingsmeddelande`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "ed1f59ed-d556-49f2-667a-cec863e009f3",
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: msg`Överlämning läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "fd2e4b2e-f4f4-479c-c758-6c784870cf13",
    templateRevision: 1,
  },
  securityReceipt: {
    title: msg`Trygghetskvitto`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "eee65ed3-d248-424c-924e-62bf06f359c7",
    templateRevision: 1,
  },
};
