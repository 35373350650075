import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { useFormContext } from "react-hook-form";
import { addDays } from "date-fns";
import { useEffect } from "react";
import { PlainButton } from "@components/Button/Button";
import styles from "./DateInput.module.scss";
import { format } from "@models/date-and-time";

export type IDateInputFields = { startDate: string; endDate: string };

export const DateInput = ({ hasEndDate = false }: { hasEndDate: boolean }) => {
  const {
    formState: { errors },
    register,
    setValue,
    unregister,
    watch,
  } = useFormContext<IDateInputFields>();

  useEffect(() => {
    if (!hasEndDate) {
      unregister("endDate");
    }
  }, [hasEndDate, unregister]);

  const handleShortcutButtonClick = (date: Date) => {
    setValue("startDate", format(date, "yyyy-MM-dd"));
  };

  const ShortcutButtons = () => (
    <div className={styles.shortcutButtons}>
      <PlainButton
        size="small"
        onClick={() => handleShortcutButtonClick(new Date())}
      >
        <Trans>Idag</Trans>
      </PlainButton>
      <PlainButton
        size="small"
        onClick={() => handleShortcutButtonClick(addDays(new Date(), 1))}
      >
        <Trans>Imorgon</Trans>
      </PlainButton>
    </div>
  );

  return hasEndDate ? (
    <Form.Row type="flex">
      <div>
        <InputField
          label={t`Startdatum`}
          showOptionalLabel={false}
          errorMessage={errors.startDate?.message}
          type="date"
          {...register(`startDate`, {
            required: {
              value: true,
              message: t`Aktiviteten behöver ett startdatum`,
            },
            min: {
              value: format(new Date(), "yyyy-MM-dd"),
              message: t`Startdatumet måste vara idag eller senare`,
            },
          })}
        />
        <ShortcutButtons />
      </div>
      <InputField
        errorMessage={errors.endDate?.message}
        key="endDate"
        label={t`Slutdatum`}
        showOptionalLabel
        type="date"
        {...register("endDate", {
          min: {
            value: watch("startDate"),
            message: t`Slutdatumet måste vara efter startdatumet`,
          },
        })}
      />
    </Form.Row>
  ) : (
    <div>
      <InputField
        label={t`Datum`}
        showOptionalLabel={false}
        errorMessage={errors.startDate?.message}
        type="date"
        {...register(`startDate`, {
          required: {
            value: true,
            message: t`Aktiviteten behöver ett datum`,
          },
          min: {
            value: format(new Date(), "yyyy-MM-dd"),
            message: t`Datumet behöver vara idag eller senare`,
          },
        })}
      />
      <ShortcutButtons />
    </div>
  );
};
