import { t } from "@lingui/core/macro";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { DEVICES_URL, GO_URL, roles } from "@/Utils/EnvUtils";
import { AppSelector } from "./login/AppSelector";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import AmbulanceCarIcon from "@components/icons/AmbulanceCarIcon";
import DevicesIcon from "@components/icons/DevicesIcon";

function Index() {
  const location = useLocation();
  const { accounts } = useMsal();
  const userRoles = accounts[0]?.idTokenClaims?.roles;
  const isAuthenticated = useIsAuthenticated();

  if (
    userRoles?.includes(roles.Values.CommandCenter) &&
    userRoles?.includes(roles.Values.Ambulating)
  ) {
    return (
      <AppSelector
        routes={[
          {
            icon: <MedicalCircleIcon />,
            label: t`Center för ledningscentralen`,
            to: "/commandcenter",
          },
          {
            icon: <MedicalCircleIcon />,
            label: t`Center för övriga`,
            to: "/center",
          },
          {
            icon: <AmbulanceCarIcon />,
            label: t`Go`,
            to: GO_URL,
          },
          {
            icon: <DevicesIcon />,
            label: t`Utrustning`,
            to: DEVICES_URL,
          },
        ]}
      />
    );
  }
  if (userRoles?.includes(roles.Values.CommandCenter)) {
    return <Navigate to="/commandcenter" replace />;
  }
  if (userRoles?.includes(roles.Values.Ambulating)) {
    return <Navigate to={GO_URL} replace />;
  }

  if (isAuthenticated) {
    return (
      <ErrorMessage
        message={t`Du har inte blivit tilldelad någon roll som ger dig behörighet att använda den här applikationen ännu. Prata med teknisk support för att lösa det.`}
      />
    );
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export default Index;
