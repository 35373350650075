import { plural, t } from "@lingui/core/macro";
import {
  recurringDictionary,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import type { ITemplate } from "./templatesSivNsvt";
import { RecurrenceChip } from "@/components/Chips/RecurrenceChip";
import Chip from "@/components/Chips/Chip";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import styles from "./TemplateHeadingSivNsvt.module.scss";
import { weekdayDictionary } from "@/forms/AddActivityForm/Scheduling/weekdaysUtils";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import {
  categorySchema,
  measurementsDictionary,
  timeOfDayDictionary,
  timeOfDaySchema,
} from "@models/activities";
import HiddenIcon from "@components/icons/HiddenIcon";
import { PatientChip } from "@/components/Chips/PatientChip";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { useLingui } from "@lingui/react";

const formatter = new Intl.ListFormat(navigator.language, {
  style: "long",
  type: "conjunction",
});

const TimeDetails = ({ template }: { template: ITemplate }) => {
  const { _ } = useLingui();
  const isRecurring =
    template.recurrence !== recurringOptionSchema.Values.never;
  const isAnyTimeOfDay = template.timeCategory === timeOfDaySchema.Values.Any;

  const timeLabel = isAnyTimeOfDay
    ? _(timeOfDayDictionary.Any.long)
    : undefined;

  const recurrenceLabel =
    template.recurrence !== recurringOptionSchema.Values.custom
      ? _(recurringDictionary[template.recurrence])
      : template.weekdays.length === 0
        ? t`Välj dagar`
        : formatter.format(
            template.weekdays.map((day) =>
              _(weekdayDictionary[day].regular).toLocaleLowerCase(),
            ),
          );

  if (isRecurring) {
    return <RecurrenceChip recurrenceLabel={recurrenceLabel} />;
  }

  if (timeLabel !== undefined) {
    return <Chip>{timeLabel}</Chip>;
  }

  return null;
};

const takeMedicationTemplateIds = [
  "e4017c05-f855-4780-2576-7201821c37bd",
  "0c90b972-ec46-46fc-47f4-005e1f01981c",
  "68581fde-bcb3-4729-16dd-703fb293896b",
  "57e5dad8-7cf8-403f-3454-23c08189ea59",
  "a0b54b96-789f-4624-2fc1-38308c63cb9c",
  "86925982-1ad8-418b-8189-2cb91240999f",
];

const MedicationCollapsiblePanelTitle = ({
  numberOfTimeSlots,
}: {
  numberOfTimeSlots: number;
}) => {
  return (
    <Text element="span">
      (
      {plural(numberOfTimeSlots, {
        one: "# tillfälle",
        other: "# tillfällen",
      })}
      )
    </Text>
  );
};

export const TemplateHeadingSivNsvt = ({
  template,
  variant = "title-and-info",
}: {
  template: ITemplate;
  variant?: "title-only" | "title-and-info";
}) => {
  const hasRequirements = template.requiredCompetences.length > 0;
  const isPerformedByPatient =
    template.category === categorySchema.Values.PatientTask ||
    template.category === categorySchema.Values.PatientMeasurementTask;

  const { _ } = useLingui();
  return (
    <div className={styles.heading}>
      <CategoryIcon category={template.category} size="small" />
      <div className={styles.titleAndChips}>
        <div className={styles.titleAndDescription}>
          <Heading level="h3" weight="regular">
            {_(template.title)}
          </Heading>
          {/* Special cases where title needs extra description */}
          {"measurements" in template && template.measurements.length > 0 ? (
            <Text element="span">
              (
              {formatter.format(
                template.measurements.map((measurement) =>
                  _(measurementsDictionary[measurement]),
                ),
              )}
              )
            </Text>
          ) : null}
          {takeMedicationTemplateIds.includes(template.templateId) &&
          "timeslots" in template ? (
            <MedicationCollapsiblePanelTitle
              numberOfTimeSlots={template.timeslots.length}
            />
          ) : null}
          {/* End */}
          {template.hidden ? <HiddenIcon /> : null}
        </div>
        {variant === "title-and-info" ? (
          <div className={styles.chips}>
            <TimeDetails template={template} />
            {isPerformedByPatient ? <PatientChip /> : null}
            {hasRequirements ? (
              template.requiredCompetences.map((competence) => (
                <RequirementChip
                  key={competence}
                  requirement={competence}
                  state="neutral"
                />
              ))
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const GroupTemplateHeadingAleris = ({ title }: { title: string }) => (
  <div className={styles.heading}>
    <CategoryIcon category="MultipleCategories" size="small" />
    <div className={styles.titleAndDescription}>
      <Heading level="h3" weight="regular">
        {title}
      </Heading>
    </div>
  </div>
);
