import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { templates } from "../templatesDefault";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { activityKeys, addActivitiesFromGroupTemplate } from "@/api/Activities";
import { addDays } from "date-fns";
import { newActivityFromTemplateDefault } from "../newActivityFromTemplateDefault";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesDefault.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import { TemplateHeadingDefault } from "../TemplateHeadingDefault";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { timeSuggestions } from "@/forms/AddActivityForm/Scheduling/timeSuggestions";
import InputField from "@/components/InputField/InputField";
import { GroupTemplateHeadingDefault } from "../TemplateHeadingDefault";
import { format } from "@models/date-and-time";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { timeOfDaySchema } from "@models/activities";

/**
 * MedicationPump Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID = "ba6c7bdc-7744-4769-bbe2-6bbe032e3717";
const TEMPLATE_REVISION = 1;

const medicationPumpActivities = {
  connectMedicationPump: templates.connectMedicationPump,
  medicationPumpReplacement: templates.medicationPumpReplacement,
  medicationPumpCheck: templates.medicationPumpCheck,
};

export const MedicationPumpTemplateDefault = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        selectedDate: string;
        time: string;
      }
  >({
    defaultValues: {
      recurrencesPerDay: "1",
      timeCategory: timeOfDaySchema.Values.Specific,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (activities: INewActivity[]) => {
      return addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: activityKeys.all,
      });
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const { startDate, time } = formData;

    const timeslots = [{ time }];

    const activities: INewActivity[] = [];

    activities.push(
      newActivityFromTemplateDefault({
        template: {
          ...medicationPumpActivities.connectMedicationPump,
          timeslots,
        },
        startDate,
        patientId,
      }),
    );
    activities.push(
      newActivityFromTemplateDefault({
        template: medicationPumpActivities.medicationPumpReplacement,
        startDate: format(addDays(new Date(startDate), 1), "yyyy-MM-dd"),
        patientId,
      }),
    );

    // Check if the time is after the medication pump check time
    // If it is, the check should be scheduled for the next day
    const startDateTime = new Date(startDate + " " + time);
    const medicationPumpCheckTime =
      "timeslots" in medicationPumpActivities.medicationPumpCheck &&
      medicationPumpActivities.medicationPumpCheck.timeslots.length > 0
        ? // Always select the first time slot since we expect only one time slot or that the first is the earliest
          Number(
            // @ts-expect-error guarded with `medicationPumpActivities.medicationPumpCheck.timeslots.length > 0`
            medicationPumpActivities.medicationPumpCheck.timeslots[0].time.split(
              ":",
            )[0],
          )
        : // If no time slot is defined, default to 00:00
          0;
    const medicationPumpCheckIsNextDay =
      startDateTime.getHours() >= medicationPumpCheckTime;

    activities.push(
      newActivityFromTemplateDefault({
        template: medicationPumpActivities.medicationPumpCheck,
        startDate: medicationPumpCheckIsNextDay
          ? format(addDays(startDateTime, 1), "yyyy-MM-dd")
          : startDate,
        patientId,
      }),
    );

    mutate(activities);
  });

  return (
    <ExpansionPanel
      trigger={<GroupTemplateHeadingDefault title={t`Läkemedelspump`} />}
      triggerAriaLabel={t`Läkemedelspump`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingDefault
                template={medicationPumpActivities.connectMedicationPump}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <InputField
                  label={t`Tid`}
                  showOptionalLabel={false}
                  errorMessage={errors.time?.message}
                  type="time"
                  suggestions={timeSuggestions}
                  {...register(`time`, {
                    required: {
                      value: true,
                      message: t`Aktiviteten behöver en tid`,
                    },
                    pattern: {
                      value: /^\d{1,2}:\d{2}$/,
                      message: t`Tid anges på formatet HH:mm`,
                    },
                  })}
                />
              </div>
            </div>
            <TemplateHeadingDefault
              template={medicationPumpActivities.medicationPumpReplacement}
            />
            <TemplateHeadingDefault
              template={medicationPumpActivities.medicationPumpCheck}
            />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
