import clsx from "clsx";
import type { HTMLInputTypeAttribute } from "react";
import { forwardRef, useId } from "react";
import { t } from "@lingui/core/macro";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./TextArea.module.scss";

interface ITextArea {
  autoComplete?: string;
  errorMessage?: string;
  formatHint?: string;
  icon?: React.ReactNode;
  name: string;
  label: React.ReactNode;
  showOptionalLabel: boolean;
  description?: React.ReactNode;
  suggestions?: string[];
  type?: HTMLInputTypeAttribute;
  width?: "full" | "fit";
  rows?: number;
}

const TextArea = forwardRef<HTMLTextAreaElement | null, ITextArea>(
  (
    {
      autoComplete,
      errorMessage,
      formatHint,
      name,
      label,
      description,
      icon,
      suggestions,
      type,
      width = ["date", "number", "time"].includes(type ?? "") ? "fit" : "full",
      rows = 3,
      showOptionalLabel,
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const descriptionId = useId();
    const displayLabel =
      `${label} ${showOptionalLabel ? t`(valfri)` : ""}`.trim();

    return (
      <div className={clsx(styles.textAreaWrapper, styles[width])}>
        {icon ? (
          <div className={styles.labelWithIcon}>
            {icon}
            <label className={styles.label} htmlFor={id}>
              {displayLabel}
            </label>
          </div>
        ) : (
          <label className={styles.label} htmlFor={id}>
            {displayLabel}
          </label>
        )}
        {description ? (
          <p id={descriptionId} className={styles.description}>
            {description}
          </p>
        ) : undefined}
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <ErrorMessage message={errorMessage} weight="bold" />
          </div>
        ) : undefined}
        <textarea
          aria-invalid={Boolean(errorMessage) || undefined}
          aria-describedby={
            description !== undefined ? descriptionId : undefined
          }
          className={styles.input}
          id={id}
          name={name}
          placeholder={formatHint}
          rows={rows}
          ref={ref}
          {...rest}
        />
      </div>
    );
  },
);
TextArea.displayName = "TextArea";

export default TextArea;
