import type { MatrixClient } from "matrix-js-sdk";

export const getEmployeeUnitPrefix = ({
  env,
  unit,
}: {
  env: string;
  unit: string;
}) => {
  return `employee-${unit}-${env}-`;
};

export const getEmployeeRoomName = ({
  employeeId,
  env,
  unit,
}: {
  employeeId: string;
  env: string;
  unit: string;
}) => {
  return `${getEmployeeUnitPrefix({ env, unit })}${employeeId}`;
};

export const getEmployeeRoomAlias = ({
  employeeId,
  env,
  matrixClient,
  unit,
}: {
  employeeId: string;
  env: string;
  matrixClient: MatrixClient | null;
  unit: string;
}) => {
  return `#${getEmployeeRoomName({ employeeId, env, unit })}:${matrixClient?.getDomain()}`;
};

export const getPatientRoomAlias = ({
  patientId,
  matrixClient,
}: {
  patientId: string;
  matrixClient: MatrixClient | null;
}) => {
  return `#${patientId}:${matrixClient?.getDomain()}`;
};
