import z from "zod";
import { logisticsApi } from "@/api/ApiClient";
import { generateQueryString } from "@/api/Helpers";
import {
  patientTypesForInvoicingSchema,
  type IPatientType,
} from "@models/patients";
import { useQuery } from "@tanstack/react-query";
import { fetchPatient } from "@/api/Patients";

export const invoicingCodeType = z.enum(["procedureCode", "productCode"]);

export type IInvoicingCodeType = z.infer<typeof invoicingCodeType>;

const invoicingCodeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  type: invoicingCodeType,
  patientType: patientTypesForInvoicingSchema,
});

export type IInvoicingCode = z.infer<typeof invoicingCodeSchema>;

export async function fetchInvoicingCodes({
  patientType,
  codeType,
}: {
  patientType: IPatientType;
  codeType: IInvoicingCodeType | null;
}) {
  const queryString = generateQueryString({ codeType, patientType });
  const response = await logisticsApi.get(
    `/invoicing/invoicing-codes${queryString}`,
  );

  return z.array(invoicingCodeSchema).parse(response.data);
}

export const useInvoicingCodes = ({
  patientId,
  codeType,
}: {
  patientId: string;
  codeType: IInvoicingCodeType | null;
}) => {
  return useQuery({
    queryKey: ["invoicingCodes", patientId, codeType],
    queryFn: async () => {
      const patient = await fetchPatient(patientId);
      return fetchInvoicingCodes({
        patientType: patient.patientType,
        codeType,
      });
    },
  });
};

export const saveActivityInvoicingCodes = async (
  activityId: string,
  patientId: string,
  procedureCodes: string[],
  productCodes: string[],
) => {
  await logisticsApi.post(`/invoicing/activities/${activityId}`, {
    patientId,
    procedureCodes,
    productCodes,
  });
};
