import { usePatient } from "@/api/Patients";
import { type IPatientType } from "@models/patients";

export const useMatchPatientTypes = (patientId: string) => {
  const { data: patient } = usePatient(patientId);

  const isPatientTypesMatching = (patientTypes: IPatientType[]) => {
    if (!patient) return false;

    return patientTypes.includes(patient.patientType);
  };

  return { isPatientTypesMatching, patient };
};
