/* eslint-disable prefer-const */
// We have a pattern to use `let` in these files. Don't mess with it.

import type { Configuration } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { UserManager, type UserManagerSettings } from "oidc-client-ts";
import { z } from "zod";

let azureAppRegistrationClientId = "abb8a983-8289-4ff6-a62a-42f333924a9e";
let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240"
    : "https://logistics-dev.medoma.com";
let devicesUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_DEVICES_BACKEND
    ? "https://localhost:7240"
    : "https://devices-dev.medoma.com";
let goUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_GO_FRONTEND
    ? "http://localhost:5000"
    : "https://go.dev.medoma.com";
let apiClientId = "2c46ab04-22e8-47dc-9c50-10b8e5b9d399";
let graphApiUrl = "https://graph.microsoft.com/v1.0";
let mapboxApiUrl = "https://api.mapbox.com";
let mapboxPublicAccessToken =
  "pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ";
let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? "https://localhost:7023"
    : "https://pas-dev.medoma.com";
let patientApiClientId = "9a7059a4-7ab2-4353-8604-3f1e9a1cce84";
let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? "https://localhost:5001"
    : "https://auth-dev.medoma.com";
let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_NOTIFICATION_BACKEND
    ? "https://localhost:7240/notification"
    : "https://logistics-dev.medoma.com/notification";
let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "app.medoma.com") {
  apiClientId = "d37df4ad-5193-4edc-86ab-0a9e4fd3915a";
  logisticsApiUrl = "https://logistics.medoma.com";
  azureAppRegistrationClientId = "f3d54325-d865-4776-a053-e7a6ede780fd";
  patientApiClientId = "8c092f1a-8dea-456d-b25f-cd0bfd0306c3";
  patientApiUrl = "https://pas.medoma.com";
  authApiUrl = "https://auth.medoma.com";
  notificationApiUrl = "https://logistics.medoma.com/notification";
  environment = "prod";
  devicesUrl = "https://devices.medoma.com";
  goUrl = "https://go.medoma.com";
} else if (hostname === "app.test.medoma.com") {
  apiClientId = "d2180b38-ca38-4353-909a-54c9923facc6";
  logisticsApiUrl = "https://logistics-test.medoma.com";
  azureAppRegistrationClientId = "b9765cd2-b881-48f8-925c-74d3f0464039";
  patientApiClientId = "c575327d-a9e2-4fde-a547-da63ed19345e";
  patientApiUrl = "https://pas-test.medoma.com";
  authApiUrl = "https://auth-test.medoma.com";
  notificationApiUrl = "https://logistics-test.medoma.com/notification";
  environment = "test";
  devicesUrl = "https://devices-test.medoma.com";
  goUrl = "https://go.test.medoma.com";
} else if (hostname === "app.demo.medoma.com") {
  apiClientId = "2b4076e8-75e5-4f04-aa26-50c3e0c51a1f";
  logisticsApiUrl = "https://logistics-demo.medoma.com";
  azureAppRegistrationClientId = "ffdcca26-98f8-479f-9383-763cc86e1341";
  patientApiClientId = "be490833-d2a7-4e50-8858-ebf34a9fb0a3";
  patientApiUrl = "https://pas-demo.medoma.com";
  authApiUrl = "https://auth-demo.medoma.com";
  notificationApiUrl = "https://logistics-demo.medoma.com/notification";
  environment = "demo";
  devicesUrl = "https://devices-demo.medoma.com";
  goUrl = "https://go.demo.medoma.com";
}

export const config: Configuration = {
  auth: {
    clientId: azureAppRegistrationClientId,
    authority:
      "https://login.microsoftonline.com/5494eb63-dea9-484a-bc5d-e6219113cab8",
    redirectUri: `${window.location.origin}/login`,
  },
};

export const scopes =
  "openid IdentityServerApi create.otp revoke_session set_temporary_pin organization";

export const oidcConfig: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-center",
  redirect_uri: `${window.location.origin}/ids-login`,
  scope:
    "openid IdentityServerApi create.otp revoke_session set_temporary_pin organization",
};

export const publicClientApplication = new PublicClientApplication(config);
export const userManager = new UserManager(oidcConfig);

export const {
  API_CLIENT_ID,
  LOGISTICS_API_URL,
  GRAPH_API_URL,
  MAPBOX_API_URL,
  MAPBOX_PUBLIC_ACCESS_TOKEN,
  PATIENT_API_CLIENT_ID,
  PATIENT_API_URL,
  AUTH_API_URL,
  NOTIFICATION_API_URL,
  DEVICES_URL,
  GO_URL,
  ENVIRONMENT,
  CHAT_ENVIRONMENT,
} = {
  API_CLIENT_ID: apiClientId,
  LOGISTICS_API_URL: logisticsApiUrl,
  GRAPH_API_URL: graphApiUrl,
  MAPBOX_API_URL: mapboxApiUrl,
  MAPBOX_PUBLIC_ACCESS_TOKEN: mapboxPublicAccessToken,
  PATIENT_API_CLIENT_ID: patientApiClientId,
  PATIENT_API_URL: patientApiUrl,
  AUTH_API_URL: authApiUrl,
  NOTIFICATION_API_URL: notificationApiUrl,
  DEVICES_URL: devicesUrl,
  GO_URL: goUrl,
  ENVIRONMENT: environment,
  CHAT_ENVIRONMENT: environment === "local" ? "dev" : environment,
};

export const roles = z.enum(["CommandCenter", "Ambulating", "Patient"]);
