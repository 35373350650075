import { t } from "@lingui/core/macro";
import styles from "./ShiftsRow.module.scss";
import { Text } from "@components/Text/Text";
import {
  TimelineActivityTile,
  TimelineVisitTile,
} from "./TimelineTile/TimelineTile";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { assignGroupToShift, type IShiftWithContents } from "@/api/Shifts";
import {
  useSelectedDate,
  useSelectedDateHasPassed,
} from "@/Utils/useSelectedDate";
import { LoadingOverlay } from "@components/Loading/Loading";
import {
  employeeName,
  medicalCompetenceDictionary,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { AllocateToShiftTile } from "./TimelineTile/AllocateToShiftTile";
import {
  routeStatusSchema,
  updateActivityOccurrencesOrderInRoute,
  type IRoute,
} from "@/api/Routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DropZone } from "@/components/DragAndDrop/DropZone";
import { useContext } from "react";
import { DropIndicator, useDragAndDrop, type Key } from "react-aria-components";
import clsx from "clsx";
import {
  MedomaGridList,
  MedomaGridListItem,
} from "@/components/DragAndDrop/DragAndDrop";
import { useLingui } from "@lingui/react";
import { ShiftsContext } from "./ShiftsContext";
import type { IVisit } from "@models/visits";
import { isGroup, type IActivityOccurrenceOrGroup } from "@models/activities";

export type ISelectedItem =
  | { item: IVisit | IActivityOccurrenceOrGroup; type: "itemOnly" }
  | { routeId: string; type: "routeOnly" }
  | {
      item: IVisit | IActivityOccurrenceOrGroup;
      shiftId: number;
      routeId: string;
      type: "shiftRouteItem";
    }
  | {
      item: IVisit | IActivityOccurrenceOrGroup;
      shiftId: number;
      type: "shiftItem";
    };

const Route = ({
  routeItem,
  isExpanded,
  shift,
  onPendingAddToShift,
}: {
  routeItem: Pick<IRoute, "id" | "visits" | "status">;
  isExpanded: boolean;
  shift: { id: number };
  onPendingAddToShift: (onPending: boolean) => void;
}) => {
  const visits = routeItem.visits;
  const routeId = routeItem.id;
  const queryClient = useQueryClient();
  const { draggedItem, setDraggedItem, selectedItem, setSelectedItem } =
    useContext(ShiftsContext);

  const hasDatePassed = useSelectedDateHasPassed();

  const { mutate: reorder, isPending: isReordering } = useMutation({
    mutationFn: (items: string[]) => {
      return updateActivityOccurrencesOrderInRoute(items, routeId);
    },
    onError: (err) => {
      displayErrorMessageAlert(
        `${t`Gick inte att flytta besöket.`} ${deducedError(err)}`,
      );
    },
    onSuccess: async () => {
      return queryClient.invalidateQueries();
    },
  });

  const { mutate: placeGroupInBlock, isPending: isPlacingGroupInBlock } =
    useMutation({
      mutationFn: ({
        groupId,
        routeId,
      }: {
        groupId: string;
        routeId: string;
      }) => assignGroupToShift(groupId, shift.id, routeId),
      onError: (err) => {
        displayErrorMessageAlert(
          `${t`Gick inte att lägga till besöket i rutten.`} ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
      onSettled: () => {
        setSelectedItem(undefined);
        setDraggedItem(undefined);
        onPendingAddToShift(false);
      },
    });

  const handleOnDrop = (droppedText: string, routeId: string) => {
    if (!draggedItem) return;

    setSelectedItem({
      routeId,
      type: "routeOnly",
    });
    placeGroupInBlock({
      groupId: droppedText,
      routeId: routeId,
    });
  };

  const { dragAndDropHooks } = useDragAndDrop({
    isDisabled: visits.length <= 1 || hasDatePassed,
    getItems: (keys) => {
      return [...keys].map((key) => {
        const visit = visits?.find((visit) => visit.id === key);

        if (!visit) {
          return {
            "medoma-item": "",
          };
        }

        return {
          "medoma-item": visit.id,
        };
      });
    },
    onReorder: (e: {
      target: { dropPosition: string; key: Key };
      keys: Iterable<Key>;
    }) => {
      const visitIdsToMove = Array.from(e.keys);
      const targetVisitId = e.target.key;

      if (visitIdsToMove.includes(targetVisitId)) {
        return;
      }

      const visitsToMove = visits.filter((visit) =>
        visitIdsToMove.includes(visit.id),
      );

      const remainingVisits = visits.filter(
        (visit) => !visitIdsToMove.includes(visit.id),
      );

      const targetIndex = remainingVisits.findIndex(
        (visit) => visit.id === targetVisitId,
      );

      if (targetIndex === -1) {
        console.error("Invalid target key provided");
        return;
      }

      const dropPositionOffset =
        e.target.dropPosition === "before"
          ? 0
          : e.target.dropPosition === "after"
            ? 1
            : undefined;

      if (dropPositionOffset === undefined) {
        console.error("Invalid drop position provided");
        return;
      }

      const reorderedVisits = [
        ...remainingVisits.slice(0, targetIndex + dropPositionOffset),
        ...visitsToMove,
        ...remainingVisits.slice(targetIndex + dropPositionOffset),
      ];

      reorder(reorderedVisits.map((visit) => visit.id));
    },
    renderDropIndicator(target) {
      return (
        <DropIndicator
          target={target}
          className={({ isDropTarget }: { isDropTarget: boolean }) =>
            clsx(styles.dropIndicator, isDropTarget && styles.dropTarget)
          }
        />
      );
    },
  });

  const addToRoute = t`Lägg till i rutt`;
  const addToRouteForEmployee = (
    employee: Parameters<typeof employeeName>[0],
  ) => {
    const nameOfEmployee = employeeName(employee, { format: "long" });
    return t`Lägg till i rutt för ${nameOfEmployee}`;
  };

  const sortVisits = t`Sortera besök`;
  const sortVisitsForEmployee = (
    employee: Parameters<typeof employeeName>[0],
  ) => {
    const nameOfEmployee = employeeName(employee, { format: "long" });
    return t`Sortera besök för ${nameOfEmployee}`;
  };

  const selectedRouteId =
    selectedItem &&
    (selectedItem.type === "routeOnly" ||
      selectedItem.type === "shiftRouteItem")
      ? selectedItem.routeId
      : null;

  const selectedShiftId =
    selectedItem &&
    (selectedItem.type === "shiftRouteItem" ||
      selectedItem.type === "shiftItem")
      ? selectedItem.shiftId
      : null;

  const selectedItemId =
    selectedItem && selectedItem.type !== "routeOnly"
      ? selectedItem.item.id
      : null;

  return (
    <LoadingOverlay
      key={routeItem.id}
      show={
        (isPlacingGroupInBlock && selectedRouteId === routeItem.id) ||
        isReordering
      }
    >
      <DropZone
        className={clsx(
          styles.dropZone,
          draggedItem &&
            isGroup(draggedItem) &&
            routeItem.status !== routeStatusSchema.Values.finished
            ? styles.showExistingRoutesAsDropZones
            : "",
        )}
        ariaLabel={
          visits[0]?.assignees[0]?.employee
            ? addToRouteForEmployee(visits[0]?.assignees[0]?.employee)
            : addToRoute
        }
        getDroppedText={(droppedText: string) =>
          handleOnDrop(droppedText, routeItem.id)
        }
        isDisabled={
          isReordering ||
          !draggedItem ||
          (draggedItem && !isGroup(draggedItem)) ||
          routeItem.status === routeStatusSchema.Values.finished
        }
      >
        <MedomaGridList
          selectionMode="single"
          aria-label={
            visits[0]?.assignees[0]?.employee
              ? sortVisitsForEmployee(visits[0]?.assignees[0]?.employee)
              : sortVisits
          }
          items={visits}
          dragAndDropHooks={dragAndDropHooks}
          dependencies={[isExpanded, selectedItem]}
          className={clsx(
            styles.existingRoute,
            draggedItem && isGroup(draggedItem)
              ? styles.aGroupIsBeingDragged
              : "",
          )}
        >
          {(visit) => (
            <MedomaGridListItem
              id={visit.id}
              textValue={visit.id}
              onAction={() => {
                setSelectedItem({
                  item: visit,
                  routeId: routeId,
                  shiftId: shift.id,
                  type: "shiftRouteItem",
                });
              }}
              className={styles.gridListItemGroup}
            >
              <TimelineVisitTile
                visit={visit}
                routeId={routeId}
                isExamining={
                  visit.id === selectedItemId && shift.id === selectedShiftId
                }
                stopExamining={() => setSelectedItem(undefined)}
                variant={isExpanded ? "default" : "compact"}
                isAloneInRoute={visits.length === 1}
              />
            </MedomaGridListItem>
          )}
        </MedomaGridList>
      </DropZone>
    </LoadingOverlay>
  );
};

export const ShiftRow = ({
  shiftWithContents: { shift, items },
  isExpanded,
  onPendingAddToShift,
}: {
  shiftWithContents: IShiftWithContents;
  isExpanded: boolean;
  onPendingAddToShift: (onPending: boolean) => void;
}) => {
  const { _ } = useLingui();
  const name = shiftName({ ...shift, options: { length: "long" } });
  const competence = shift.employee?.competence || shift.competence;
  const selectedDate = new Date(useSelectedDate());
  const { selectedItem, setSelectedItem } = useContext(ShiftsContext);

  const { startDateTime, endDateTime } = shift;
  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate,
    startDateTime,
    endDateTime,
  });
  const displayShiftTime = `${
    startedDayBefore
      ? t`Start ${formattedDayBefore}`
      : hasEndTimeAfterToday
        ? t`Start ${formattedDayToday}`
        : ""
  } ${formattedTimeSpan(startDateTime, endDateTime)}`;

  const selectedShiftId =
    selectedItem &&
    (selectedItem.type === "shiftRouteItem" ||
      selectedItem.type === "shiftItem")
      ? selectedItem.shiftId
      : null;

  const selectedItemId =
    selectedItem && selectedItem.type !== "routeOnly"
      ? selectedItem.item.id
      : null;

  return (
    <li
      className={clsx(
        styles.shiftRow,
        styles[`variant-${isExpanded ? "default" : "compact"}`],
      )}
    >
      <div className={styles.shiftPerson}>
        <AllocateToShiftTile
          shift={shift}
          onPendingAddToShift={onPendingAddToShift}
        >
          <div className={styles.personGrid}>
            <CompetenceChip
              competence={competence}
              state="neutral"
              content={_(medicalCompetenceDictionary[competence].short)}
            />
            <Text element="span" weight="medium">
              {name}
            </Text>
            <div></div>
            <Text element="p" size="small">
              {displayShiftTime}
            </Text>
          </div>
        </AllocateToShiftTile>
      </div>
      <div className={styles.routesAndActivities}>
        {items.length !== 0
          ? items.map((item) => {
              // Show routes
              if ("visits" in item) {
                const routeItem = item;

                return (
                  <Route
                    routeItem={routeItem}
                    isExpanded={isExpanded}
                    shift={shift}
                    onPendingAddToShift={onPendingAddToShift}
                    key={routeItem.id}
                  />
                );

                // TODO: Deprecated, only used when there are no routes in the response.
              } else if ("occurrences" in item) {
                const visitItem = item;
                return (
                  <div key={visitItem.id} className={styles.dropZone}>
                    <TimelineVisitTile
                      visit={visitItem}
                      isExamining={visitItem.id === selectedItemId}
                      stopExamining={() => setSelectedItem(undefined)}
                      variant={isExpanded ? "default" : "compact"}
                      routeId={"no-route-id-for-deprecated-visit"}
                      isAloneInRoute={true}
                    />
                  </div>
                );

                // Show activity occurrences
              } else if ("activityId" in item) {
                const occurrenceItem = item;
                return (
                  <button
                    key={occurrenceItem.id}
                    onClick={() =>
                      setSelectedItem({
                        item: occurrenceItem,
                        shiftId: shift.id,
                        type: "shiftItem",
                      })
                    }
                    className={styles.activityOccurrence}
                  >
                    <TimelineActivityTile
                      isExamining={
                        occurrenceItem.id === selectedItemId &&
                        shift.id === selectedShiftId
                      }
                      stopExamining={() => setSelectedItem(undefined)}
                      activityOccurrence={occurrenceItem}
                      variant={isExpanded ? "default" : "compact"}
                    />
                  </button>
                );
              }
            })
          : null}
      </div>
    </li>
  );
};
