import React from "react";
import clsx from "clsx";
import styles from "./Heading.module.scss";

export type IHeading = React.HTMLAttributes<HTMLHeadingElement> & {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  weight?: "regular" | "medium" | "bold";
  margin?: "top" | "bottom" | "top-and-bottom";
};

export const Heading = ({
  level,
  size = level,
  weight = "bold",
  margin,
  children,
  ...props
}: IHeading) => {
  const htmlElement = level;

  const HeadingElement = ({
    ...props
  }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(htmlElement, props, children);

  return (
    <HeadingElement
      {...props}
      className={clsx(
        styles[size],
        styles[`weight-${weight}`],
        margin && styles[`margin-${margin}`],
        props.className,
      )}
    >
      {children}
    </HeadingElement>
  );
};
