import { t } from "@lingui/core/macro";
import type { SortingState } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { IPatientsTableItem } from "./PatientsTable";
import { PatientsTable } from "./PatientsTable";
import type { IListAdmittedPatient } from "@/api/Patients";
import { useAdmittedPatients, usePatientTypes } from "@/api/Patients";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import NoResults from "@/components/NoResults/NoResults";
import { useMemo, useState } from "react";
import NotificationsCell from "./NotificationsCell";
import NameCell from "./NameCell";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";
import { PATIENT_TABLE_COLUMN_DISPLAY_NAMES } from "./patientsTableUtils";
import type { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { patientTypeDictionary } from "@models/patients";

type IAdmittedPatientsItem = IPatientsTableItem<IListAdmittedPatient>;

const COLUMN_DISPLAY_NAMES = {
  ...PATIENT_TABLE_COLUMN_DISPLAY_NAMES,
};

const columnHelper = createColumnHelper<IAdmittedPatientsItem>();

const getDataFromPatients = (patients: IListAdmittedPatient[]) => {
  return patients.map((patient) => {
    return {
      patient: patient,
      notifications: patient.id,
      ward: patient.ward,
      admittedAt: patient.admittedAt,
      personalIdentityNumber: patient.personalIdentityNumber,
      patientType: patient.patientType,
      area: patient.area,
    };
  });
};

const columns = (translator: I18n["_"]) => [
  columnHelper.display({
    id: "notifications",
    cell: ({ row: { original } }) => {
      const patientId = original.patient.id;
      return <NotificationsCell patientId={patientId} />;
    },
  }),
  columnHelper.accessor("patient", {
    id: "patient",
    header: translator(COLUMN_DISPLAY_NAMES["patient"]),
    cell: ({ getValue }) => {
      const patient = getValue();
      return <NameCell patient={patient} />;
    },
    sortingFn: (a, b) => {
      const aPatientName = a
        .getValue<IListAdmittedPatient>("patient")
        .name.toLowerCase();
      const bPatientName = b
        .getValue<IListAdmittedPatient>("patient")
        .name.toLowerCase();

      if (aPatientName < bPatientName) {
        return -1;
      } else if (aPatientName > bPatientName) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("personalIdentityNumber", {
    id: "personalIdentityNumber",
    header: translator(COLUMN_DISPLAY_NAMES["personalIdentityNumber"]),
    cell: ({ getValue }) => {
      const notFourLastDigits = getValue().slice(0, -4);
      return notFourLastDigits;
    },
  }),
  columnHelper.accessor("patientType", {
    id: "patientType",
    header: translator(COLUMN_DISPLAY_NAMES["patientType"]),
    cell: ({ getValue }) => {
      const patientType = getValue();
      return translator(patientTypeDictionary[patientType]);
    },
  }),
  columnHelper.accessor("area", {
    id: "area",
    header: translator(COLUMN_DISPLAY_NAMES["area"]),
    cell: ({ getValue }) => {
      const area = getValue();
      return area ? area.displayName : "";
    },
  }),
  columnHelper.accessor("ward", {
    id: "ward",
    header: translator(COLUMN_DISPLAY_NAMES["ward"]),
    cell: ({ getValue }) => {
      const ward = getValue();
      return ward.displayName;
    },
  }),
];

export const AdmittedPatients = () => {
  const { _ } = useLingui();
  const { data: patients, isError, isPending, error } = useAdmittedPatients({});
  const { data: cardiologyEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CardiologyWorkflow,
  );
  const { data: areaFeatureFlag } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.Area,
  );

  const { data: patientTypes } = usePatientTypes();

  const data: IAdmittedPatientsItem[] = useMemo(
    () => getDataFromPatients(patients || []),
    [patients],
  );

  const featuredColumns = useMemo(() => {
    const translatedColumns = columns(_);
    return translatedColumns.filter((column) => {
      return column.id === "ward" && !cardiologyEnabled
        ? false // Don't display ward column if cardiology is not enabled
        : column.id === "patientType" &&
            (!patientTypes || patientTypes.length <= 1)
          ? false // Don't display patient type column if patient type is not fetched or 0-1
          : column.id === "area" && !areaFeatureFlag
            ? false // Don't display area column if area is not enabled
            : true;
    });
  }, [cardiologyEnabled, patientTypes, areaFeatureFlag, _]);

  const [sorting] = useState<SortingState>([{ id: "patient", desc: false }]);

  const table = useReactTable({
    data,
    columns: featuredColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  if (isPending) {
    return <Loading message={t`Hämtar inskrivna patienter`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta inskrivna patienter.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  if (patients.length === 0) {
    return (
      <NoResults
        message={t`Det finns inga inskrivna patienter här`}
        padding={24}
      />
    );
  }

  return <PatientsTable<IAdmittedPatientsItem> table={table} />;
};
