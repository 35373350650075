import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { patientKeys, updateHomeCare } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";

export const EditHomeCare = ({
  currentHomeCare,
  onSuccess,
  patientId,
}: {
  currentHomeCare: boolean | null | undefined;
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      homeCare: currentHomeCare,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (homeCare: boolean | null | undefined) =>
      updateHomeCare(patientId, homeCare),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.homeCare);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar hemtjänst`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <Checkbox
              label={{ text: t`Hemtjänst` }}
              {...register("homeCare")}
            />
          </Form.Row>
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        </>
      )}
    </Form>
  );
};
