import { t } from "@lingui/core/macro";
import { competenceColor } from "@/Utils/ColorUtils";
import type { IChip } from "./Chip";
import Chip from "./Chip";
import type { IMedicalCompetence } from "@models/shifts";
import { medicalCompetenceDictionary } from "@models/shifts";
import {
  activityRequirementStatusSchema,
  type IActivityRequirementStatus,
} from "@models/activities";
import CrossIcon from "@components/icons/CrossIcon";
import { getBackground, getBorder } from "./chipUtils";
import { useLingui } from "@lingui/react";

export const CompetenceChip = ({
  competence,
  state,
  content,
  onRemove,
  size,
  disabled = false,
}: {
  competence: IMedicalCompetence;
  state: IActivityRequirementStatus;
  content?: string;
  onRemove?: () => void;
  disabled?: boolean;
} & Pick<IChip, "size">) => {
  const { _ } = useLingui();
  const label = content ?? _(medicalCompetenceDictionary[competence].short);
  const medicalCompetence = _(medicalCompetenceDictionary[competence].long);

  return (
    <Chip
      color={competenceColor[competence].colorName}
      onClick={
        state === activityRequirementStatusSchema.Values.assigned &&
        onRemove !== undefined
          ? onRemove
          : undefined
      }
      iconEnd={
        state === activityRequirementStatusSchema.Values.assigned &&
        onRemove !== undefined ? (
          <CrossIcon />
        ) : undefined
      }
      aria-label={t`Ta bort ${medicalCompetence}`}
      background={getBackground(state)}
      border={getBorder(state)}
      size={size}
      disabled={disabled}
    >
      {label}
    </Chip>
  );
};
