import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import {
  fetchCoordinates,
  patientKeys,
  updateAddress,
  usePatientInformationFields,
} from "@/api/Patients";
import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { MapProvider, Marker, useMap } from "react-map-gl";
import { FilledButton } from "@components/Button/Button";
import { useCallback } from "react";
import type { ICoordinatePair } from "@/components/Map/Map";
import Map from "@/components/Map/Map";
import type { IAddressWithCoordinates } from "@models/addresses";
import { useState, useEffect } from "react";
import InputField from "@/components/InputField/InputField";
import TextArea from "@/components/TextArea/TextArea";
import styles from "./EditAddress.module.scss";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { useLingui } from "@lingui/react";

const formatAddress = ({
  addressLine1,
  city,
  postalCode,
  additionalInformation,
  coordinates,
}: IAddressWithCoordinates) => {
  return {
    addressLine1,
    city,
    // Remove all spaces from postal code before sending to backend
    postalCode: postalCode.replaceAll(" ", ""),
    additionalInformation,
    coordinates: {
      // @ts-expect-error Longitude is usually a number, but can be a string if the input fields have been manually touched.
      longitude: parseFloat(coordinates.longitude),
      // @ts-expect-error Latitude is usually a number, but can be a string if the input fields have been manually touched.
      latitude: parseFloat(coordinates.latitude),
    },
  };
};

export const EditAddress = ({
  currentAddress,
  onSuccess,
  patientId,
}: {
  currentAddress: Omit<IAddressWithCoordinates, "addressLine2">;
  onSuccess: () => void;
  patientId: string;
}) => {
  return (
    <MapProvider>
      <InnerEditAddress
        currentAddress={currentAddress}
        onSuccess={onSuccess}
        patientId={patientId}
      />
    </MapProvider>
  );
};

const InnerEditAddress = ({
  currentAddress,
  onSuccess,
  patientId,
}: {
  currentAddress: Omit<IAddressWithCoordinates, "addressLine2">;
  onSuccess: () => void;
  patientId: string;
}) => {
  const { data: mapboxGeocodingV6, isPending: isPendingFeatureFlagResolution } =
    useFeatureFlag(knownFeatureFlagsSchema.Values.MapboxGeocodingV6);

  const {
    formState: { errors, dirtyFields, isDirty },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    reset,
    trigger,
    watch,
  } = useForm<IAddressWithCoordinates>({
    defaultValues: {
      ...currentAddress,
    },
  });

  const PREFERRED_EDIT_ADDRESS_ZOOM = 13;
  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (address: IAddressWithCoordinates) =>
      updateAddress(patientId, address),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const updatedAddress = formatAddress(formData);

    mutate(updatedAddress);
  });

  const setCoordinates = useCallback(
    (coordinates: ICoordinatePair) => setValue("coordinates", coordinates),
    [setValue],
  );

  const { registerPatientMap } = useMap();

  const triggerCoordinateValidation = useCallback(
    () => trigger(["coordinates.latitude", "coordinates.longitude"]),
    [trigger],
  );

  const { addressLine1, postalCode, city, coordinates } = watch();
  const coordinatesProvided =
    Boolean(coordinates.latitude && coordinates.longitude) ||
    Boolean(
      dirtyFields.coordinates?.latitude || dirtyFields.coordinates?.longitude,
    );

  const [coordinatesFailedToFetch, setCoordinatesFailedToFetch] =
    useState(false);

  useEffect(() => {
    if (!registerPatientMap) return;

    registerPatientMap.easeTo({
      center: [coordinates.longitude, coordinates.latitude],
      duration: 1000,
      zoom: PREFERRED_EDIT_ADDRESS_ZOOM,
    });
  }, [registerPatientMap, coordinates.latitude, coordinates.longitude]);

  useEffect(() => {
    if (isPendingFeatureFlagResolution) {
      return;
    }
    const handler = setTimeout(() => {
      if (addressLine1 && postalCode && (mapboxGeocodingV6 || city)) {
        fetchCoordinates(
          mapboxGeocodingV6
            ? {
                addressLine1,
                postalCode,
                apiVersion: "6",
              }
            : {
                addressLine1,
                postalCode,
                city,
                apiVersion: "5",
              },
        )
          .then((coordinates) => {
            setCoordinatesFailedToFetch(false);
            setCoordinates(coordinates);
            triggerCoordinateValidation();
          })
          .catch(() => {
            setCoordinatesFailedToFetch(true);
          });
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [
    addressLine1,
    postalCode,
    city,
    setCoordinates,
    triggerCoordinateValidation,
    mapboxGeocodingV6,
    isPendingFeatureFlagResolution,
  ]);

  const { _ } = useLingui();

  const {
    data: conditionalInformationFields,
    isPending: isPendingConditionalInformationFields,
    isError: isErrorConditionalInformationFields,
    error: errorConditionalInformationFields,
  } = usePatientInformationFields();

  if (isPendingConditionalInformationFields) {
    return <Loading message={_(msg`Hämtar information om valbara fält`)} />;
  }

  if (isErrorConditionalInformationFields) {
    return (
      <ErrorMessage
        message={`${_(msg`Kunde inte hämta information om valbara fält.`)} ${deducedError(errorConditionalInformationFields)}`}
      />
    );
  }

  return (
    <Form onSubmit={validateAndSubmit}>
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar adressen`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <>
            <InputField
              label={t`Gata`}
              showOptionalLabel={false}
              errorMessage={errors.addressLine1?.message}
              {...register("addressLine1", {
                required: { value: true, message: t`Gata behövs` },
              })}
            />
            <Form.Row>
              <InputField
                label={t`Postnummer`}
                showOptionalLabel={false}
                errorMessage={errors.postalCode?.message}
                {...register("postalCode", {
                  required: { value: true, message: t`Postnummer behövs` },
                  pattern: {
                    value: /^\d{3}\s?\d{2}$/,
                    message: t`Postnumret måste anges som 5 siffror`,
                  },
                })}
              />
              <InputField
                label={t`Postort`}
                showOptionalLabel={false}
                errorMessage={errors.city?.message}
                {...register("city", {
                  required: { value: true, message: t`Postort behövs` },
                })}
              />
            </Form.Row>
            {conditionalInformationFields.AdditionalAdressInformation ===
            "hidden" ? null : (
              <TextArea
                label={t`Övrig adressinformation`}
                showOptionalLabel={
                  conditionalInformationFields.AdditionalAdressInformation ===
                  "optional"
                }
                {...register("additionalInformation", {
                  required: {
                    value:
                      conditionalInformationFields.AdditionalAdressInformation ===
                      "required",
                    message: t`Övrig adressinformation behövs`,
                  },
                })}
                formatHint={t`T.ex. har nyckel, portkod`}
                errorMessage={errors.additionalInformation?.message}
              />
            )}
            <Form.Row>
              <fieldset
                className={styles.fieldset}
                disabled={!coordinatesProvided && !coordinatesFailedToFetch}
              >
                <legend>
                  {!coordinatesProvided && !coordinatesFailedToFetch
                    ? t`Fyll i adress för att se plats`
                    : coordinatesFailedToFetch
                      ? t`Kartan kan inte laddas. Fyll i koordinater manuellt.`
                      : t`Plats`}
                </legend>
                <div className={styles.mapAndCoordinatesSection}>
                  <Map
                    initialAreaToShow={{
                      center: {
                        lat: coordinates.latitude,
                        lng: coordinates.longitude,
                      },
                      zoom: PREFERRED_EDIT_ADDRESS_ZOOM,
                    }}
                    disabled={!coordinatesProvided}
                    id="registerPatientMap"
                    style={{ height: "30vh" }}
                  >
                    {coordinatesProvided &&
                    !coordinatesFailedToFetch &&
                    coordinates.longitude &&
                    coordinates.latitude ? (
                      <Marker
                        longitude={coordinates.longitude}
                        latitude={coordinates.latitude}
                      />
                    ) : (
                      <></>
                    )}
                  </Map>
                  <div className={styles.coordinateSection}>
                    <Form.Row>
                      <InputField
                        label={t`Longitud`}
                        showOptionalLabel={false}
                        errorMessage={errors.coordinates?.longitude?.message}
                        {...register("coordinates.longitude", {
                          required: {
                            value: true,
                            message: t`Longitud behöver anges`,
                          },
                          min: {
                            value: 17,
                            message: t`Longitud bör vara över 17`,
                          },
                          max: {
                            value: 19,
                            message: t`Longitud bör vara under 19`,
                          },
                        })}
                      />
                      <InputField
                        label={t`Latitud`}
                        showOptionalLabel={false}
                        errorMessage={errors.coordinates?.latitude?.message}
                        {...register("coordinates.latitude", {
                          required: {
                            value: true,
                            message: t`Latitud behöver anges`,
                          },
                          min: {
                            value: 58.3,
                            message: t`Latitud bör vara över 58.3`,
                          },
                          max: {
                            value: 60.3,
                            message: t`Latitud bör vara under 60.3`,
                          },
                        })}
                      />
                    </Form.Row>
                    {coordinatesProvided ? (
                      <p>
                        <Trans>
                          Fyll i koordinater manuellt om kartnålen är
                          felplacerad
                        </Trans>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </fieldset>
            </Form.Row>
          </>
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        </>
      )}
    </Form>
  );
};
