import { format } from "@models/date-and-time";
import { startOfDay } from "date-fns";
import { createContext, useContext } from "react";

export const SelectedDateContext = createContext<{
  selectedDate: string | undefined;
  setSelectedDate: (_date: string) => void;
} | null>(null);

export const useSetSelectedDate = () => {
  const selectedDateContext = useContext(SelectedDateContext);
  return selectedDateContext?.setSelectedDate
    ? selectedDateContext?.setSelectedDate
    : undefined;
};

export const useSelectedDateNoDefault = () => {
  const selectedDateContext = useContext(SelectedDateContext);
  return selectedDateContext?.selectedDate
    ? selectedDateContext?.selectedDate
    : undefined;
};

export const useSelectedDate = () => {
  const now = new Date();
  return useSelectedDateNoDefault() ?? format(now, "yyyy-MM-dd");
};

export const useSelectedDateHasPassed = () => {
  const now = new Date();
  const selectedDate = useSelectedDate();
  const hasPassed = startOfDay(selectedDate) < startOfDay(now);
  return hasPassed;
};
