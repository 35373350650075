import { createContext, useContext } from "react";

export const RoutesContext = createContext<{
  selectedRouteId: string | undefined;
  setSelectedRouteId: (_routeId: string | undefined) => void;
}>({
  selectedRouteId: undefined,
  setSelectedRouteId: () => {},
});

export const useRoutesContext = () => useContext(RoutesContext);
