import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Popover } from "@/components/Popover/Popover";
import type { ITitleInputFields } from "@/forms/AddActivityForm/TitleInput";
import { TitleInput } from "@/forms/AddActivityForm/TitleInput";
import { useTitleSuggestions } from "@/forms/AddActivityForm/titleSuggestions";
import { FilledButton } from "@components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { CategoryIcon } from "../CategoryIcon/CategoryIcon";
import styles from "./QuickActivityPopover.module.scss";
import Chip from "../Chips/Chip";
import { routeKeys, useRoute, useVisit, visitKeys } from "@/api/Routes";
import { Loading, LoadingOverlay } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { getPatientNameWithStatus } from "@/api/Patients";
import { AssignedShiftChip } from "../Chips/AssignedShiftChip";
import type { ICompetencePickerFields } from "@/forms/AddActivityForm/CompetencePicker";
import { CompetencePicker } from "@/forms/AddActivityForm/CompetencePicker";
import { Heading } from "@components/Heading/Heading";
import { dateName } from "@/Utils/DateUtils";
import Form from "../Form/Form";
import PlusIcon from "@components/icons/PlusIcon";
import { generateRandomUUID } from "@/Utils/UniqueId";
import type { INewActivityInVisit } from "@/api/Activities";
import {
  activityOccurrenceAndGroupKeys,
  addActivityInVisit,
} from "@/api/Activities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { flushSync } from "react-dom";
import { categorySchema, timeOfDayDictionary } from "@models/activities";
import { useLingui } from "@lingui/react";

// Deprecated, tied to `ActivityGrouping = false`.
export const QuickActivityPopoverWithRoutesAndVisits = ({
  routeId,
  visitId,
}: {
  routeId: string;
  visitId: string;
}) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);
  const { data: visit, isPending, isError, error } = useVisit(routeId, visitId);
  const {
    data: route,
    isPending: isPendingRoute,
    isError: isErrorRoute,
    error: errorRoute,
  } = useRoute(routeId);

  const titleSuggestions = useTitleSuggestions();

  const methods = useForm<ITitleInputFields & ICompetencePickerFields>();
  const {
    setError,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: ({ newActivity }: { newActivity: INewActivityInVisit }) =>
      addActivityInVisit(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },

    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: activityOccurrenceAndGroupKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: visitKeys(routeId).all(),
      });
      await queryClient.invalidateQueries({
        queryKey: routeKeys.lists(),
      });
      // Use `flushSync` to avoid flicker where `onSuccess` is done, while `setOpen` is not quite done.
      flushSync(() => {
        setOpen(false);
      });
      reset();
    },
  });

  const validateAndSubmit = methods.handleSubmit((validatedFormData) => {
    const { requiredCompetences, title } = validatedFormData;

    const newActivity: INewActivityInVisit = {
      activityId: generateRandomUUID(),
      requiredCompetences,
      routeId,
      title,
      visitId,
    };

    mutate({ newActivity });
  });

  const date = route ? dateName(new Date(route.date)) : undefined;

  return (
    <Popover
      controlled={{
        open,
        onOpenChange: setOpen,
      }}
      trigger={{
        icon: <PlusIcon />,
        text: t`Ny aktivitet`,
        size: "small",
      }}
    >
      {isPending || isPendingRoute ? (
        <Loading message={t`Laddar`} />
      ) : isError ? (
        <ErrorMessage
          message={`${t`Kunde inte hämta information om besöket.`} ${deducedError(error)}`}
        />
      ) : isErrorRoute ? (
        <ErrorMessage
          message={`${t`Kunde inte hämta information om rutten.`} ${deducedError(errorRoute)}`}
        />
      ) : (
        <div className={styles.container}>
          <FormProvider {...methods}>
            <LoadingOverlay
              show={isAdding}
              message={t`Lägger till aktiviteten`}
            >
              <Form onSubmit={validateAndSubmit}>
                {errors.root?.server?.message && !isDirty ? (
                  <ErrorMessage message={errors.root.server.message} />
                ) : undefined}
                <TitleInput
                  suggestions={titleSuggestions.HomeVisit.map(
                    (untranslatedSuggestion) => _(untranslatedSuggestion),
                  )}
                />
                <CompetencePicker isRequired />
                <div>
                  <Heading level="h2" size="h3" weight="medium">
                    <Trans>Övrigt</Trans>
                  </Heading>
                  <div className={styles.additionalConfig}>
                    <CategoryIcon
                      category={categorySchema.Values.HomeVisit}
                      size="small"
                    />
                    <Chip>{getPatientNameWithStatus(visit.patient)}</Chip>
                    {date ? <Chip>{date}</Chip> : null}
                    <Chip>{_(timeOfDayDictionary.Any.long)}</Chip>
                    {visit.assignees?.map((assignee) => (
                      <AssignedShiftChip
                        key={assignee.id}
                        medicalCompetence={assignee.competence}
                        shift={assignee}
                      />
                    ))}
                  </div>
                </div>

                <div className={styles.submitButton}>
                  <FilledButton disabled={isAdding} type="submit">
                    <Trans>Skapa aktiviteten</Trans>
                  </FilledButton>
                </div>
              </Form>
            </LoadingOverlay>
          </FormProvider>
        </div>
      )}
    </Popover>
  );
};
