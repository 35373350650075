import { msg, t } from "@lingui/core/macro";
import { useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import type { IMedicalCompetence } from "@models/shifts";
import {
  medicalCompetenceDictionary,
  medicalCompetenceSchema,
} from "@models/shifts";
import { useLingui } from "@lingui/react";
import { useEnabledCompetences } from "@/api/Shifts";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";

export type ICompetencePickerFields = {
  requiredCompetences: IMedicalCompetence[];
};

const CompetencePicker = ({ isRequired = false }: { isRequired?: boolean }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ICompetencePickerFields>();
  const { _ } = useLingui();
  const {
    data: enabledCompetences,
    isPending,
    isError,
  } = useEnabledCompetences();

  return (
    <Checkboxes
      legend={isRequired ? t`Kompetensbehov` : t`Kompetensbehov (valfri)`}
      errorMessage={errors.requiredCompetences?.message}
      orientation="horizontal"
    >
      {isPending ? (
        <Loading message={_(msg`Hämtar lista med kompetenser`)} />
      ) : isError ? (
        <ErrorMessage
          message={_(msg`Kunde inte hämta lista med kompetenser`)}
        />
      ) : (
        enabledCompetences.map((competence) => (
          <Checkbox
            key={competence}
            label={{ text: _(medicalCompetenceDictionary[competence].long) }}
            {...register(`requiredCompetences`, {
              required: {
                value: isRequired,
                message: t`Minst en kompetens behöver anges`,
              },
            })}
            value={medicalCompetenceSchema.Values[competence]}
            visualStyle="framed"
          />
        ))
      )}
    </Checkboxes>
  );
};

export { CompetencePicker };
