import { routeKeys } from "@/api/Routes";
import { activityKeys } from "@/api/Activities";
import { useEventSource } from "@/Utils/sse";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { patientsWithUnhandledMeasurementsQueryOptions } from "@/api/Patients";
import { shiftKeys } from "@/api/Shifts";

const ALL_KNOWN_LOGISTICS_EVENTS = [
  "routeCreated",
  "routeRemoved",
  "visitOrderChanged",
  "routeFinished",
  "routeNameChanged",
  "visitCreated",
  "visitRemoved",
  "travelToVisitStarted",
  "visitStarted",
  "visitFinished",
  "travellingToVisitReverted",
  "homeVisitActivityRegistered",
  "videoCallActivityRegistered",
  "patientTaskActivityRegistered",
  "patientMeasurementTaskActivityRegistered",
  "adminTaskActivityRegistered",
  "activityVisibilitySet",
  "titleChanged",
  "descriptionChanged",
  "durationChanged",
  "activityEndDateSet",
  "activityClosed",
  "activityOccurrenceAssigned",
  "activityOccurrenceUnassigned",
  "occurrenceAllocated",
  "occurrenceDeallocated",
  "occurrenceRemoved",
  "occurrenceReset",
  "occurrenceFinished",
  "occurrenceCreated",
  "occurrenceRescheduled",
  "scheduleFrequencyChanged",
  "homeVisitCompetenceChanged",
  "videoCallCompetenceChanged",
  "homeVisitDoubleStaffingChanged",
  "adminTaskPatientConnected",
  "adminTaskCompetenceChanged",
  "patientMeasurementTaskMeasurementsChanged",
  "occurrenceGroupProcessingCompleted",
];

// These keys are the parents of every known logistics query, dealing with activities, routes etc.
const ALL_KNOWN_LOGISTICS_QUERY_KEYS = [
  routeKeys.all,
  activityKeys.all,
  shiftKeys.listWithContents({}),
];

export const useCenterLogisticsSSEHandlers = () => {
  const queryClient = useQueryClient();
  // https://react.dev/reference/react/useMemo#caveats says to prefer useState for safely persisting state between renders: https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state
  const [eventHandlers] = useState(() => [
    {
      relevantEvents: ALL_KNOWN_LOGISTICS_EVENTS,
      handler: () => {
        ALL_KNOWN_LOGISTICS_QUERY_KEYS.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });
      },
    },
  ]);

  useEventSource("logistics", eventHandlers);
};

export const useCenterPatientSSEHandlers = () => {
  const queryClient = useQueryClient();
  // https://react.dev/reference/react/useMemo#caveats says to prefer useState for safely persisting state between renders: https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state
  const [eventHandlers] = useState(() => [
    // Ensure unhandled measurements-collection is refreshed when measurements are changed
    {
      relevantEvents: [
        "bloodPressureMeasurementRegistered",
        "pulseMeasurementRegistered",
        "saturationMeasurementRegistered",
        "temperatureMeasurementRegistered",
        "weightMeasurementRegistered",
        "bloodGlucoseMeasurementRegistered",
        "measurementAcknowledged",
        "measurementDiscarded",
      ],
      handler: () => {
        queryClient.invalidateQueries({
          queryKey: patientsWithUnhandledMeasurementsQueryOptions.queryKey,
        });
      },
    },
  ]);
  useEventSource("patient", eventHandlers);
};
