import { dateSchema } from "@models/date-and-time";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi } from "./ApiClient";
import { useFeatureFlag } from "./FeatureFlags";

const activeVehicleSchema = z.object({
  id: z.string().uuid(),
  alias: z.string().nullish(),
  licensePlate: z.string().nullish(),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
    inMovement: z.boolean(),
    timestamp: dateSchema,
  }),
  driver: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      phoneNumber: z.string(),
      email: z.string().nullish(),
    })
    .nullish(),
});

const vehicleSchema = activeVehicleSchema.extend({
  location: activeVehicleSchema.shape.location.nullish(),
});

async function fetchVehicles() {
  const vehiclesResponse = await logisticsApi.get(`/vehicles`);
  const parsedVehicles = z.array(vehicleSchema).parse(vehiclesResponse.data);
  const activeVehicles = parsedVehicles.filter(({ location }) =>
    Boolean(location),
  );
  const parsedActiveVehicles = z
    .array(activeVehicleSchema)
    .parse(activeVehicles);
  return parsedActiveVehicles;
}

export const useVehicles = () => {
  const { data: shouldRequestVehicles } = useFeatureFlag("PERMANENT_UseAbax");
  return useQuery({
    queryKey: ["vehicles", shouldRequestVehicles],
    queryFn: fetchVehicles,
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchInterval: 1000 * 30, // 30 seconds
    enabled: shouldRequestVehicles !== false,
  });
};
