import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import { FilledButton } from "@components/Button/Button";
import styles from "./ChangeGroup.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import type { IActivityRequirement } from "@/api/Activities";
import {
  changeGroup,
  useEligibleGroups,
  usePatientsActivityOccurrencesAndGroups,
} from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import RadioButton from "@/components/RadioButton/RadioButton";
import {
  activityOccurrenceStatusSchema,
  isGroup,
  type IGroupOfHomeVisitActivityOccurrenceWithPatientId,
  type IHomeVisitActivityOccurrence,
  type IHomeVisitActivityOccurrenceWithPatientId,
} from "@models/activities";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { Text } from "@components/Text/Text";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import type { IScheduledShift } from "@models/shifts";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import NoResults from "@/components/NoResults/NoResults";
import { useLingui } from "@lingui/react";
import { format } from "@models/date-and-time";

export const CompetencesAndAssignees = ({
  unfulfilledRequirements,
  assignees,
}: {
  unfulfilledRequirements: IActivityRequirement[];
  assignees: IScheduledShift[];
}) => {
  return (
    <div className={styles.competencesAndAssignees}>
      {unfulfilledRequirements.length > 0 ? (
        <ul className={styles.competences}>
          {unfulfilledRequirements.map((unfulfilledRequirement) => (
            <li key={unfulfilledRequirement}>
              <RequirementChip
                requirement={unfulfilledRequirement}
                state="unfulfilled"
                size="small"
              />
            </li>
          ))}
        </ul>
      ) : undefined}
      {assignees.length > 0 ? (
        <ul className={styles.assignees}>
          {assignees.map((shift) => (
            <li key={shift.id}>
              <AssignedShiftChip
                medicalCompetence={shift.competence}
                shift={shift}
                size="small"
              />
            </li>
          ))}
        </ul>
      ) : undefined}
    </div>
  );
};

export const NEW_GROUP_ID = "new-group";

const CustomRadioButton = (
  group: IGroupOfHomeVisitActivityOccurrenceWithPatientId,
) => {
  const { _ } = useLingui();
  return (
    <>
      <div className={styles.groupDetails}>
        <Text element="span">
          {group.timeOfDay === timeOfDaySchema.Values.Any
            ? _(timeOfDayDictionary.Any.short)
            : formattedTimeSpan(group.start, group.end)}
        </Text>
        <CompetencesAndAssignees
          assignees={group.assignees}
          unfulfilledRequirements={getUnfulfilledRequirements(group)}
        />
      </div>
      <Text element="span" size="small">
        {group.occurrences.map(({ title }) => title).join(", ")}
      </Text>
    </>
  );
};

interface IFormData {
  groupId: string;
}

export const ChangeGroup = ({
  activityOccurrence,
  onSubmitSuccess,
}: {
  activityOccurrence: Pick<
    IHomeVisitActivityOccurrence,
    "activityId" | "id" | "status" | "start" | "end"
  > &
    (
      | Pick<IHomeVisitActivityOccurrence, "patient">
      | Pick<IHomeVisitActivityOccurrenceWithPatientId, "patientId">
    );
  onSubmitSuccess?: () => void;
}) => {
  const { _ } = useLingui();

  const { activityId, id: occurrenceId, status } = activityOccurrence;
  const patientId =
    "patientId" in activityOccurrence
      ? activityOccurrence.patientId
      : activityOccurrence.patient.id;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormData>();

  const {
    data: eligibleGroups,
    isPending: isPendingGroups,
    isError: isErrorGroups,
  } = useEligibleGroups({ activityId, occurrenceId });

  const {
    data: activityOccurrencesAndGroups,
    isPending: isPendingActivityOccurrencesAndGroups,
  } = usePatientsActivityOccurrencesAndGroups(
    patientId,
    format(activityOccurrence.start, "yyyy-MM-dd"),
    format(activityOccurrence.start, "yyyy-MM-dd"),
  );

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (groupId: string) =>
      changeGroup(activityId, occurrenceId, groupId),
    onError: (error) => {
      setError("groupId", {
        message: deducedError(error),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      onSubmitSuccess?.();
    },
  });

  const activityOccurrenceIsMoveable = () => {
    return (
      status !== activityOccurrenceStatusSchema.Values.ongoing ||
      window.confirm(
        t`Är du säker på att du vill flytta en aktivitet från ett pågående besök?`,
      )
    );
  };

  const isActivityOccurrenceAloneInGroup: "alone" | "notAlone" | "unknown" =
    (() => {
      if (!activityOccurrencesAndGroups) return "unknown";

      const groupStatus = activityOccurrencesAndGroups.some(
        (activityOccurrenceOrGroup) => {
          return (
            isGroup(activityOccurrenceOrGroup) &&
            activityOccurrenceOrGroup.occurrences.some(
              (occurrence) =>
                occurrence.id === occurrenceId &&
                occurrence.activityId === activityId,
            ) &&
            activityOccurrenceOrGroup.occurrences.length === 1
          );
        },
      );

      return groupStatus ? "alone" : "notAlone";
    })();

  const validateAndSubmit = handleSubmit((formData) => {
    const { groupId } = formData;

    return activityOccurrenceIsMoveable() ? mutate(groupId) : null;
  });

  const canMoveToNewGroup = isActivityOccurrenceAloneInGroup === "notAlone";
  const canMoveToExistingGroup =
    eligibleGroups !== undefined && eligibleGroups.length >= 1;
  const canMove = canMoveToNewGroup || canMoveToExistingGroup;

  if (isPendingActivityOccurrencesAndGroups || isPendingGroups) {
    return <Loading message={t`Laddar passande besök`} />;
  }

  return (
    <div className={styles.changeGroup}>
      {!canMove ? (
        <NoResults
          message={t`Det finns inget annat besök att flytta denna aktivitet till`}
        />
      ) : (
        <Form onSubmit={validateAndSubmit}>
          <RadioButtons
            errorMessage={errors.groupId?.message}
            orientation="vertical"
            width="fill-container"
          >
            {canMoveToNewGroup ? (
              <RadioButton
                label={{
                  text: t`Nytt hembesök`,
                }}
                visualStyle="framed"
                value={NEW_GROUP_ID}
                {...register(`groupId`, {
                  required: {
                    value: true,
                    message: t`Ett hembesök måste väljas`,
                  },
                })}
              />
            ) : null}
            <>
              {isErrorGroups ? (
                <ErrorMessage message={t`Kunde inte hämta besök`} />
              ) : (
                eligibleGroups.map((group) => (
                  <RadioButton
                    key={group.id}
                    label={{
                      text: `${
                        group.timeOfDay === timeOfDaySchema.Values.Any
                          ? _(timeOfDayDictionary.Any.short)
                          : formattedTimeSpan(group.start, group.end)
                      } ${group.occurrences.map(({ title }) => title).join(", ")}`,
                      component: CustomRadioButton(group),
                    }}
                    visualStyle="framed"
                    value={group.id}
                    {...register(`groupId`)}
                  />
                ))
              )}
            </>
          </RadioButtons>
          <Form.SubmitButtonWrapper>
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Flytta</Trans>
            </FilledButton>
          </Form.SubmitButtonWrapper>
        </Form>
      )}
    </div>
  );
};
