import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import Select from "@/components/Select/Select";
import { useInvoicingCodes } from "@/api/Invoicing";
import { useLingui } from "@lingui/react";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Heading } from "@components/Heading/Heading";
import { useFormContext } from "react-hook-form";

export type IInvoicingCodesPickerFields = {
  procedureCode: string;
  productCode: string;
};

export const InvoicingPicker = ({ patientId }: { patientId: string }) => {
  const { _ } = useLingui();
  const {
    formState: { errors },
    register,
  } = useFormContext<IInvoicingCodesPickerFields>();

  const {
    data: invoicingCodes,
    isPending,
    isError,
  } = useInvoicingCodes({
    patientId,
    codeType: null,
  });

  if (isPending) {
    return (
      <div>
        <Heading level="h3" weight="medium" margin="bottom">
          <Trans>Fakturering</Trans>
        </Heading>
        <Loading message={t`Hämtar faktureringskoder`} />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Heading level="h3" weight="medium" margin="bottom">
          <Trans>Fakturering</Trans>
        </Heading>
        <ErrorMessage message={t`Kunde inte hämta faktureringskoder`} />
      </div>
    );
  }

  const procedureCodes = invoicingCodes.filter(
    (code) => code.type === "procedureCode",
  );

  const productCodes = invoicingCodes.filter(
    (code) => code.type === "productCode",
  );

  const hasProcedureCodes = procedureCodes.length > 0;
  const hasProductCodes = productCodes.length > 0;

  return (
    <>
      {hasProcedureCodes ? (
        <Select
          label={t`Åtgärd (KVÅ)`}
          {...register("procedureCode")}
          errorMessage={errors.procedureCode?.message}
        >
          <option value="">
            <Trans>Ingen</Trans>
          </option>
          {procedureCodes.map((procedureCode) => (
            <option key={procedureCode.id} value={procedureCode.id}>
              {`${procedureCode.code}, ${_(procedureCode.name)}`}
            </option>
          ))}
        </Select>
      ) : null}
      {hasProductCodes ? (
        <Select
          label={t`Produkt`}
          {...register("productCode")}
          errorMessage={errors.productCode?.message}
        >
          <option value="">
            <Trans>Ingen</Trans>
          </option>
          {productCodes.map((productCode) => (
            <option key={productCode.id} value={productCode.id}>
              {`${productCode.code}, ${_(productCode.name)}`}
            </option>
          ))}
        </Select>
      ) : null}
    </>
  );
};
