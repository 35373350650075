import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import {
  patientKeys,
  updateHealthcareJourneyNumber,
  usePatientInformationFields,
} from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

export const EditHealthcareJourneyNumber = ({
  currentHealthcareJourneyNumber,
  onSuccess,
  patientId,
}: {
  currentHealthcareJourneyNumber: string | null | undefined;
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      healthcareJourneyNumber: currentHealthcareJourneyNumber,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (healthcareJourneyNumber: string | null | undefined) =>
      updateHealthcareJourneyNumber(patientId, healthcareJourneyNumber),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  const { _ } = useLingui();

  const {
    data: conditionalInformationFields,
    isPending: isPendingConditionalInformationFields,
    isError: isErrorConditionalInformationFields,
    error: errorConditionalInformationFields,
  } = usePatientInformationFields();

  if (isPendingConditionalInformationFields) {
    return <Loading message={_(msg`Hämtar information om valbara fält`)} />;
  }

  if (isErrorConditionalInformationFields) {
    return (
      <ErrorMessage
        message={`${_(msg`Kunde inte hämta information om valbara fält.`)} ${deducedError(errorConditionalInformationFields)}`}
      />
    );
  }

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.healthcareJourneyNumber);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar sjukresenummer`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <InputField
              label={t`Sjukresenummer`}
              showOptionalLabel={
                conditionalInformationFields.MedicalTransportNumber ===
                "optional"
              }
              errorMessage={errors.healthcareJourneyNumber?.message}
              {...register("healthcareJourneyNumber", {
                pattern: {
                  value: /^\d{8}$/,
                  message: t`Ange sjukresenummer med 8 siffror`,
                },
                required: {
                  value:
                    conditionalInformationFields.MedicalTransportNumber ===
                    "required",
                  message: t`Sjukresenummer behövs`,
                },
              })}
            />
          </Form.Row>
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        </>
      )}
    </Form>
  );
};
