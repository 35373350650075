import { t } from "@lingui/core/macro";
import type { SortingState } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { IPatientsTableItem } from "./PatientsTable";
import { PatientsTable } from "./PatientsTable";
import type { IListExistingPatient } from "@/api/Patients";
import { usePatients, usePatientTypes } from "@/api/Patients";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import NoResults from "@/components/NoResults/NoResults";
import { useMemo, useState } from "react";
import NotificationsCell from "./NotificationsCell";
import NameCell from "./NameCell";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";
import { PATIENT_TABLE_COLUMN_DISPLAY_NAMES } from "./patientsTableUtils";
import { useNavigate, useSearchParams } from "react-router";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { useForm } from "react-hook-form";
import styles from "./AllPatients.module.scss";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import type { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { patientTypeDictionary } from "@models/patients";

type IPatientsItem = IPatientsTableItem<IListExistingPatient>;

const COLUMN_DISPLAY_NAMES = {
  ...PATIENT_TABLE_COLUMN_DISPLAY_NAMES,
};

const columnHelper = createColumnHelper<IPatientsItem>();

const getDataFromPatients = (patients: IListExistingPatient[]) => {
  return patients.map((patient) => {
    return {
      patient: patient,
      notifications: patient.id,
      ward: patient.ward,
      personalIdentityNumber: patient.personalIdentityNumber,
      patientType: patient.patientType,
      area: patient.area,
    };
  });
};

const columns = (translator: I18n["_"]) => [
  columnHelper.display({
    id: "notifications",
    cell: ({ row: { original } }) => {
      const patientId = original.patient.id;
      return <NotificationsCell patientId={patientId} />;
    },
  }),
  columnHelper.accessor("patient", {
    id: "patient",
    header: translator(COLUMN_DISPLAY_NAMES["patient"]),
    cell: ({ getValue }) => {
      const patient = getValue();
      return <NameCell patient={patient} />;
    },
    sortingFn: (a, b) => {
      const aPatientName = a
        .getValue<IListExistingPatient>("patient")
        .name.toLowerCase();
      const bPatientName = b
        .getValue<IListExistingPatient>("patient")
        .name.toLowerCase();

      if (aPatientName < bPatientName) {
        return -1;
      } else if (aPatientName > bPatientName) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("personalIdentityNumber", {
    id: "personalIdentityNumber",
    header: translator(COLUMN_DISPLAY_NAMES["personalIdentityNumber"]),
    cell: ({ getValue }) => {
      const notFourLastDigits = getValue().slice(0, -4);
      return notFourLastDigits;
    },
  }),
  columnHelper.accessor("patientType", {
    id: "patientType",
    header: translator(COLUMN_DISPLAY_NAMES["patientType"]),
    cell: ({ getValue }) => {
      const patientType = getValue();
      return translator(patientTypeDictionary[patientType]);
    },
  }),
  columnHelper.accessor("area", {
    id: "area",
    header: translator(COLUMN_DISPLAY_NAMES["area"]),
    cell: ({ getValue }) => {
      const area = getValue();
      return area ? area.displayName : "";
    },
  }),
  columnHelper.accessor("ward", {
    id: "ward",
    header: translator(COLUMN_DISPLAY_NAMES["ward"]),
    cell: ({ getValue }) => {
      const ward = getValue();
      return ward.displayName;
    },
  }),
];

const SearchPanel = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSubmit, register, reset } = useForm<{
    name: string;
  }>();
  const validateAndSubmit = handleSubmit(({ name }) => {
    if (name) {
      navigate(`?filter=${name}`);
    } else {
      navigate(".");
    }
  });
  const currentFilter = searchParams.get("filter");
  return (
    <>
      <search className={styles.searchPanel}>
        <Form onSubmit={validateAndSubmit}>
          <InputField
            width="fit"
            type="search"
            label={t`Sök patient`}
            showOptionalLabel={false}
            {...register("name")}
          />
        </Form>
      </search>
      {currentFilter ? (
        <div className={styles.infoWrapper}>
          <InlineAlert
            type="info"
            title={t`Sökt på patientnamn "${currentFilter}"`}
            placement="fill"
            actions={[
              {
                label: t`Rensa sökning`,
                onClick: () => {
                  reset({ name: "" });
                  navigate(".");
                },
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

const Table = () => {
  const { _ } = useLingui();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter") ?? "";
  const {
    data: patients,
    isPending,
    isError,
    error,
  } = usePatients({
    statuses: ["prospect", "admitted", "declined", "discharged"],
  });
  const { data: cardiologyEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CardiologyWorkflow,
  );
  const { data: areaFeatureFlag } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.Area,
  );

  const { data: patientTypes } = usePatientTypes();

  const data = useMemo(() => {
    const filteredPatients =
      !filter || !patients
        ? patients
        : patients.filter((patient) =>
            patient.name.toLowerCase().includes(filter.toLowerCase()),
          );
    return getDataFromPatients(filteredPatients || []);
  }, [patients, filter]);

  const featuredColumns = useMemo(() => {
    const translatedColumns = columns(_);
    return translatedColumns.filter((column) => {
      return column.id === "ward" && !cardiologyEnabled
        ? false // Don't display ward column if cardiology is not enabled
        : column.id === "patientType" &&
            (!patientTypes || patientTypes.length <= 1)
          ? false // Don't display patient type column if patient type is not fetched or 0-1
          : column.id === "area" && !areaFeatureFlag
            ? false // Don't display area column if area is not enabled
            : true;
    });
  }, [cardiologyEnabled, patientTypes, areaFeatureFlag, _]);

  const [sorting] = useState<SortingState>([{ id: "patient", desc: false }]);

  const table = useReactTable({
    data,
    columns: featuredColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  if (isPending) {
    return <Loading message={t`Hämtar patienter`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta patienterna.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  if (table.getRowCount() === 0) {
    return (
      <NoResults
        message={t`Det finns inga patienter som matchar sökningen`}
        padding={24}
      />
    );
  }

  return <PatientsTable<IPatientsItem> table={table} />;
};

export const AllPatients = () => {
  return (
    <>
      <SearchPanel />
      <Table />
    </>
  );
};
